import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { rgba, darken } from 'polished';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import colors, {
  BLACK,
  MAIN_BLACK,
  LIGHTGREY,
  MISCHKA,
  ROYAL_BLUE_2,
  BITTERSWEET,
  SHAMROCK,
  WHITE,
  PURPLE,
  ZUMTHOR,
} from '@/constants/colors';

const breakpoints = createBreakpoints({});

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.primaryText,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.text,
      secondary: colors.textSecondary,
    },
    background: {
      default: colors.bg,
      contentHero: ROYAL_BLUE_2,
    },
    success: {
      main: colors.primary,
      contrastText: colors.primaryText,
    },
    error: {
      main: colors.error,
      contrastText: colors.primaryText,
    },
    warning: {
      main: colors.warning,
      contrastText: colors.text,
    },
    type: 'light',
    home: {
      left: ROYAL_BLUE_2,
      leftText: WHITE,
      right: colors.primary,
      rightText: WHITE,
    },
    subheader: {
      background: WHITE,
      color: colors.text,
    },
    showMovieCard: {
      hoverBg: MAIN_BLACK,
      hoverText: WHITE,
      hoverTextSecondary: MISCHKA,
    },
    bundle: {
      border: WHITE,
      highlighted: ROYAL_BLUE_2,
    },
  },
  overrides: {
    MuiAlert: {
      root: {
        borderRadius: 12,
        padding: 16,
        '&.sizeSmall': {
          padding: '6px 8px',
          borderRadius: 4,
          '& .MuiAlert-icon': {
            marginRight: 6,
            display: 'flex !important',
          },
          '& .MuiAlertTitle-root': {
            margin: '0 !important',
            whiteSpace: 'nowrap',
          },
        },
        [breakpoints.up('md')]: {
          padding: 20,
          '&.sizeLarge': {
            '& .MuiAlert-icon': {
              width: 33,
              height: 33,

              '& > svg': {
                width: 33,
                height: 33,
              },
            },
          },
        },
        '&.primary': {
          '& .MuiAlert-message > :not(.MuiAlertTitle-root)': {
            color: MAIN_BLACK,
          },
        },
        '&.secondary': {
          backgroundColor: MAIN_BLACK,
          color: WHITE,
        },
      },
      message: {
        padding: 0,
      },
      icon: {
        width: 20,
        height: 20,
        padding: 0,
        marginRight: 12,

        '& > svg': {
          width: 20,
          height: 20,
        },

        [breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      standardError: {
        color: BITTERSWEET,
        backgroundColor: rgba(BITTERSWEET, 0.25),

        '& .MuiAlert-icon': {
          color: BITTERSWEET,
        },
      },
      standardSuccess: {
        color: SHAMROCK,
        backgroundColor: rgba(SHAMROCK, 0.15),

        '& .MuiAlert-icon': {
          color: SHAMROCK,
        },
      },
      standardInfo: {
        color: MAIN_BLACK,
        backgroundColor: ZUMTHOR,
        boxShadow: `7px 5px 7.2px 0px ${rgba('#595959', 0.07)}`,

        '& .MuiAlert-icon': {
          color: PURPLE,
        },
      },
    },
    MuiAlertTitle: {
      root: {
        color: 'inherit',
        margin: '0 0 12px !important',
        lineHeight: '3rem',
        fontSize: '2.2rem',
        fontWeight: 600,
      },
    },
    // GRID
    MuiGrid: {
      'spacing-xs-10': { margin: 0, width: '100%' },
      'spacing-xs-8': { margin: 0, width: '100%' },
    },
    // PAPER
    MuiPaper: {
      root: {
        '&.detail-statistic': {
          //
        },
      },
      rounded: {
        borderRadius: '6px',
      },
      elevation1: {
        boxShadow: 'none',
        padding: '2.6rem 3rem',
      },
      elevation4: {
        boxShadow: `0px 1px 0 ${colors.paper.elevation4}`,
      },
      elevation5: {
        boxShadow: `0px 1px 20px ${colors.paper.elevation5}`,
      },
      elevation8: {
        boxShadow: `0px 2px 4px ${colors.paper.boxShadow}`,
      },
      elevation9: {
        boxShadow: `0px 20px 40px ${colors.paper.elevation9}`,
      },
    },
    // POPOVER
    MuiPopover: {
      paper: {
        minHeight: 'auto',
        minWidth: 'auto',
      },
    },
    // BUTTON
    MuiButton: {
      root: {
        fontWeight: 600,
        textTransform: 'inherit',
        borderRadius: '2rem',
        padding: '1.3rem 2rem',
        fontSize: '1.4rem',
        lineHeight: '1.4rem',

        '&:hover': {
          backgroundColor: colors.buttons.bgHover,
        },

        '&.sizeExtraLarge': {
          fontSize: '1.6rem',
          lineHeight: '1.6rem',
          padding: '1.5rem 3.2rem',
          borderRadius: '2.3rem',

          '&.MuiButton-outlined': {
            padding: '1.3rem 3rem',
          },
        },
      },
      sizeLarge: {
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        padding: '1.7rem 3.5rem',
        borderRadius: '2.5rem',

        '&.MuiButton-outlinedSizeLarge': {
          padding: '1.5rem 3.3rem',
        },
      },
      sizeSmall: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        padding: '1.1rem 2rem',

        '&.MuiButton-outlinedSizeSmall': {
          padding: '0.9rem 1.8rem',
        },
      },
      iconSizeLarge: {
        '&> *:first-child': {
          fontSize: '1.8rem',
          marginTop: '-1px',
          marginBottom: '-1px',
        },
      },
      iconSizeMedium: {
        '&> *:first-child': {
          fontSize: '1.6rem',
          marginTop: '-1px',
          marginBottom: '-1px',
        },
      },
      iconSizeSmall: {
        '&> *:first-child': {
          fontSize: '1.6rem',
          marginTop: '-2px',
          marginBottom: '-2px',
        },
      },
      contained: {
        backgroundColor: colors.buttons.contained.bg,
        color: colors.buttons.contained.color,
        boxShadow: 'none',

        '&:active, &:hover': {
          boxShadow: 'none',
        },

        '&:hover': {
          backgroundColor: colors.buttons.bgHover,
        },

        '&.danger': {
          backgroundColor: colors.buttons.danger.contained.bg,
          color: colors.buttons.danger.contained.text,

          '&:hover': {
            backgroundColor: colors.buttons.danger.contained.bgHover,
          },

          '&.Mui-disabled': {
            color: rgba(BLACK, 0.26),
            backgroundColor: rgba(BLACK, 0.12),
          },
        },

        '&.grey': {
          backgroundColor: colors.buttons.grey.contained.bg,
          color: colors.buttons.grey.contained.text,

          '&:hover': {
            backgroundColor: colors.buttons.grey.contained.bgHover,
          },

          '&.Mui-disabled': {
            color: rgba(BLACK, 0.26),
            backgroundColor: rgba(BLACK, 0.12),
          },
        },

        '&.purple': {
          backgroundColor: colors.buttons.purple.contained.bg,
          color: colors.buttons.purple.contained.text,

          '&:hover': {
            backgroundColor: colors.buttons.purple.contained.bgHover,
          },

          '&.Mui-disabled': {
            color: rgba(BLACK, 0.26),
            backgroundColor: rgba(BLACK, 0.12),
          },
        },
      },
      outlined: {
        border: `0.2rem solid ${colors.buttons.outlined.border}`,
        padding: '1.1rem 1.8rem',

        '&.Mui-disabled': {
          borderWidth: '0.2rem',
        },

        '&.danger': {
          borderColor: colors.buttons.danger.outlined.border,
          color: colors.buttons.danger.outlined.text,

          '&:hover': {
            backgroundColor: colors.buttons.danger.outlined.bgHover,
          },

          '&.Mui-disabled': {
            color: rgba(BLACK, 0.26),
            backgroundColor: rgba(BLACK, 0.12),
          },
        },

        '&.grey': {
          borderColor: colors.buttons.grey.outlined.border,
          color: colors.buttons.grey.outlined.text,

          '&:hover': {
            backgroundColor: colors.buttons.grey.outlined.bgHover,
          },

          '&.Mui-disabled': {
            color: rgba(BLACK, 0.26),
            backgroundColor: rgba(BLACK, 0.12),
          },
        },
      },
      outlinedPrimary: {
        border: `0.2rem solid ${colors.primary}`,

        '&:hover': {
          borderWidth: '0.2rem',
        },
      },
      outlinedSecondary: {
        border: `0.2rem solid ${colors.secondary}`,

        '&:hover': {
          borderWidth: '0.2rem',
        },

        '&.Mui-disabled': {
          borderWidth: '0.2rem',
        },
      },
    },
    MuiButtonGroup: {
      root: {
        '&.type-secondary': {
          '& > button': {
            fontSize: '1.4rem',
            flexGrow: '1',
            width: '100%',

            '&.sizeExtraLarge': {
              padding: '1.5rem',
            },

            '&:not(:first-of-type)': {
              marginLeft: '2px',
            },
            '&:not(:last-of-type)': {
              marginRight: '2px',
            },
          },
          [breakpoints.down(375)]: {
            '& > button': {
              paddingLeft: '0.5rem !important',
              paddingRight: '0.5rem !important',
              minWidth: 'auto !important',
            },
          },
        },
      },
      contained: {
        boxShadow: 'none',
      },
      groupedContainedHorizontal: {
        '&:not(:last-child)': {
          borderRight: '0',

          '&.Mui-disabled': {
            borderRight: '0',
          },
        },
      },
    },
    //   // LINK
    MuiLink: {
      root: {
        '&[disabled]': {
          cursor: 'default',
          color: MISCHKA,
        },
      },
    },
    // FORM
    MuiFormLabel: {
      root: {
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        fontWeight: 500,
      },
    },
    // ADORNMENT
    MuiInputAdornment: {
      root: {
        color: colors.inputs.defaultIcon,

        '&.success': {
          color: colors.primary,
        },
      },
      marginDense: {
        fontSize: '1.8rem',
      },
      filled: {
        '&.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
          marginTop: 0,
        },
      },
    },
    // HELP TEXT
    MuiFormHelperText: {
      root: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        fontWeight: 400,
        marginTop: '.8rem',
      },
    },
    // INPUT
    MuiInputBase: {
      root: {
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        fontWeight: 500,

        '&.Mui-disabled': {
          backgroundColor: colors.inputs.filled.bg,

          '& .MuiSelect-icon': {
            fill: rgba(BLACK, 0.26),
          },
        },

        '&.sizeSmall': {
          fontSize: '1.4rem',
          lineHeight: '1.4rem',

          '& .MuiSelect-select': {
            minHeight: '4rem',
          },

          '& .MuiSelect-icon': {
            fontSize: '1.6rem',
            top: 'calc(50% - 8px)',
          },

          '& .MuiSelect-iconOutlined': {
            right: '1rem',
          },

          '& .MuiSelect-outlined': {
            padding: '1.3rem',

            '&.MuiSelect-outlined': {
              paddingRight: '3rem',
            },
          },
        },
      },
      input: {
        height: 'auto',
        padding: '2rem 3rem',
        borderRadius: '.3rem',
        boxSizing: 'border-box',

        '&::-webkit-input-placeholder': {
          color: colors.text,
          opacity: 1,
        },
        '-webkit-appearance': 'none',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: colors.inputs.filled.bg,
        color: colors.inputs.filled.color,
        borderRadius: '4px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0.3rem',
      },
      input: {
        padding: '2rem 3rem',
      },
      multiline: {
        padding: '2rem 3rem',
      },
      notchedOutline: {
        borderColor: colors.inputs.border,
      },
      inputMarginDense: {
        padding: '0 1.5rem',
        paddingTop: '1.15rem',
        paddingBottom: '1.15rem',
        fontSize: '1.4rem',
        lineHeight: '1.4rem',

        '& > .MuiInputAdornment-root': {
          fontSize: '1rem !important',
        },
      },
      adornedStart: {
        paddingLeft: '2.5rem',
      },
      marginDense: {
        '&.MuiOutlinedInput-adornedStart': {
          paddingLeft: '1.5rem',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(30px, 23px) scale(1)',

        '&.MuiInputLabel-shrink': {
          transform: 'translate(18px, -5px) scale(0.75)',
        },
      },
    },
    // SELECT
    MuiSelect: {
      select: {
        minHeight: '5.9rem',
        '&:focus': {
          borderRadius: '.3rem',
          backgroundColor: colors.bg,
        },
        // '&[aria-pressed="true"]': {
        //   borderColor: colors.selectOpenedBorder,
        // },
      },
      outlined: {
        borderRadius: '.3rem',
        padding: '2.1rem 3rem',

        '&.MuiSelect-outlined': {
          paddingRight: '5rem',
        },
      },
      icon: {
        fontSize: '2.4rem',
        top: 'calc(50% - 12px)',
        fill: colors.text,
      },
      iconOutlined: {
        right: '2.5rem',
      },
    },
    // FORM CONTROL LABEL
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
      label: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        fontWeight: 500,
        marginLeft: '8px',
      },
    },
    // MENU
    MuiMenu: {
      paper: {
        '&.MuiPaper-elevation8': {
          boxShadow: `0px 10px 20px ${rgba(BLACK, 0.1)}`,
        },
        '&.MuiPaper-elevation7': {
          boxShadow: `4px 4px 16px 0px ${rgba(BLACK, 0.1)}`,
        },
        '&.MuiPaper-rounded': {
          borderRadius: '3px',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        fontWeight: 500,
        paddingTop: '16px',
        paddingBottom: '16px',
      },
    },
    // LIST
    MuiList: {
      padding: {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected, &.Mui-selected:hover, &.MuiListItem-button:hover': {
          color: colors.primary,
          backgroundColor: colors.listItem.bg,
          '&.secondary': {
            backgroundColor: colors.bg,
          },
        },
      },
      gutters: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
    // TYPOGRAPHY
    MuiTypography: {
      root: {
        '&.m-b-30': {
          marginBottom: 30,
        },
      },
      noWrap: {
        maxWidth: '100%',
      },
      button: {
        fontSize: '1.6rem',
        fontWeight: 600,
        textTransform: 'inherit',
      },
      subtitle2: {
        '& > .MuiLink-root': {
          fontSize: '1.8rem',
          lineHeight: '2.4rem',
        },
      },
    },
    // CHECKBOX
    MuiCheckbox: {
      root: {
        padding: 0,

        '& .icon-correct-symbol': {
          '&.default:before': {
            content: '""',
            borderColor: colors.checkbox.border,
          },

          '&:before': {
            height: '22px',
            width: '22px',
            borderRadius: '3px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${colors.primary}`,
          },
        },
      },
      colorPrimary: {
        '&.Mui-checked': {
          color: colors.primaryText,

          '& .icon-correct-symbol': {
            '&:before': {
              backgroundColor: colors.primary,
            },
          },

          '& + .MuiFormControlLabel-label': {
            color: colors.primary,
          },
        },
        '&.Mui-checked:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    // AUTOCOMPLETE
    MuiAutocomplete: {
      root: {
        '& .MuiCircularProgress-root': {
          position: 'absolute',
          right: '15px',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.bg,
          boxShadow: `0 10px 30px ${rgba(BLACK, 0.1)}`,
        },
      },
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          paddingLeft: '2.5rem',
        },
        '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
          padding: '8px 15px',
        },
      },
      paper: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        fontWeight: '500',
        marginTop: 0,
        marginBottom: 0,
        borderRadius: '0 0 3px 3px',
        padding: '0',
        boxShadow: `0 20px 30px ${rgba(BLACK, 0.1)}`,
      },
      listbox: {
        padding: '10px 0',

        [breakpoints.down('xs')]: {
          maxHeight: '20vh',
        },
      },
      option: {
        minHeight: '36px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '15px',
        paddingRight: '15px',

        '&[data-focus="true"]': {
          backgroundColor: colors.listItem.bg,
        },
        '&[aria-selected="true"]': {
          color: colors.primary,
          backgroundColor: colors.listItem.bg,
        },
      },
      popupIndicator: {
        top: '4px',
      },
      clearIndicator: {
        top: '4px',
      },
    },
    // Dialog
    MuiDialog: {
      paper: {
        borderRadius: 20,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: colors.dialog.bg,
        backdropFilter: 'blur(6px)',
      },
    },
    // Divider
    MuiDivider: {
      root: {
        backgroundColor: colors.divider,
      },
    },
    // Chip
    MuiChip: {
      root: {
        height: '24px',
        fontSize: '1.2rem',
        fontWeight: 600,
        lineHeight: '2.4rem',
        color: colors.chip.text,
        backgroundColor: colors.chip.bg,
        borderRadius: '4px',

        '&.danger': {
          color: colors.chip.danger.text,
          backgroundColor: colors.chip.danger.bg,
          '&.MuiChip-clickable': {
            '&:hover, &:focus': {
              backgroundColor: colors.chip.danger.hoverBg,
            },
          },
        },

        '&.warning': {
          color: colors.chip.warning.text,
          backgroundColor: colors.chip.warning.bg,
          '&.MuiChip-clickable': {
            '&:hover, &:focus': {
              backgroundColor: colors.chip.warning.hoverBg,
            },
          },
        },
      },
      outlined: {
        color: colors.chip.outlined.text,
        backgroundColor: colors.chip.outlined.bg,
        borderColor: colors.chip.outlined.borderColor,
      },
      clickable: {
        '&:active': {
          boxShadow: 'none',
        },
        '&:hover, &:focus': {
          backgroundColor: colors.chip.hoverBg,
        },
      },
      label: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      deleteIcon: {
        height: '18px',
        width: '18px',
      },
      colorSecondary: {
        color: colors.chip.secondary.text,
        backgroundColor: colors.chip.secondary.bg,
        '&.MuiChip-clickable': {
          '&:hover, &:focus': {
            backgroundColor: colors.chip.secondary.hoverBg,
          },
        },
      },
      colorPrimary: {
        '&.light': {
          color: colors.chip.primaryLight.text,
          backgroundColor: colors.chip.primaryLight.bg,
          fontWeight: 600,
          '&.MuiChip-clickable': {
            '&:hover, &:focus': {
              backgroundColor: colors.chip.primaryLight.hoverBg,
            },
          },
        },
      },
    },
    // TABS
    MuiTabs: {
      root: {
        height: 'auto',

        '& .MuiTabs-indicator': {
          display: 'none',
        },

        '& .MuiTabs-flexContainer': {
          flexWrap: 'wrap',
          justifyContent: 'center',
        },
      },
    },
    // TAB
    MuiTab: {
      root: {
        minWidth: 0,
        height: '50px',
        padding: '18px 30px 16px',
        color: colors.tabs.text,
        backgroundColor: colors.tabs.bg,
        borderRadius: '25px',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        marginRight: '20px',
        marginBottom: '8px',
        transition: 'all 0.4s ease',
        maxWidth: 'none',

        '&:last-of-type': {
          marginRight: '0',
        },

        [breakpoints.up('xs')]: {
          minWidth: 0,
        },

        '&$selected': {
          backgroundColor: colors.primary,
          color: colors.tabs.activeText,
        },

        '& .MuiTab-wrapper': {
          flexDirection: 'row',

          '& .sub-label': {
            marginLeft: '20px',
          },
        },
      },
    },
    // LINEAR PROGRESS
    MuiLinearProgress: {
      root: {
        height: 14,
        borderRadius: 7,
      },
      bar: {
        borderRadius: 7,
      },
      colorPrimary: {
        backgroundColor: colors.linearProgress.bg,
      },
      barColorPrimary: { backgroundColor: colors.linearProgress.barBg },
    },
    // TOOLTIP
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.tooltip.bg,
        color: colors.tooltip.text,
        fontSize: '1.2rem',
        lineHeight: '1.4rem',
        fontWeight: 400,
        maxWidth: '452px',
        borderRadius: '3px',
        padding: '6px 10px',

        [breakpoints.down('xs')]: {
          maxWidth: '340px',
        },

        '& > .tooltip-large': {
          fontSize: '1.6rem',
          lineHeight: '2rem',
        },

        '& .tooltip-content': {
          display: 'inline-flex',
          fontSize: '1.2rem',
          lineHeight: '1.4rem',

          '& > button': {
            marginLeft: '5px',
            flexShrink: 0,
          },
        },

        '& button': {
          fontSize: '1.2rem',
          lineHeight: '1.4rem',
        },
      },
      popperArrow: {
        '&[x-placement*="left"]': {
          '& .MuiTooltip-arrow::before': {
            borderColor: `transparent transparent transparent ${colors.tooltip.bg} !important`,
          },
        },
        '&[x-placement*="right"]': {
          '& .MuiTooltip-arrow::before': {
            borderColor: `transparent ${colors.tooltip.bg} transparent transparent !important`,
          },
        },
        '&[x-placement*="top"]': {
          '& .MuiTooltip-arrow::before': {
            borderColor: `${colors.tooltip.bg} transparent transparent transparent !important`,
          },
        },
        '&[x-placement*="bottom"]': {
          '& .MuiTooltip-arrow::before': {
            borderColor: `transparent transparent ${colors.tooltip.bg} transparent !important`,
          },
        },
      },
      arrow: {
        color: colors.tooltip.bg,
      },
    },
    // RATING
    MuiRating: {
      root: {
        color: colors.rating.active,
        fontSize: '1.8rem',

        '& .MuiRating-decimal': {
          '&:not(:last-of-type)': {
            marginRight: '8px',
          },
        },

        '& .MuiRating-label': {
          '&:not(:last-of-type) .MuiRating-icon': {
            marginRight: '8px',
          },
        },

        '&.sizeLarge': {
          '& .MuiRating-iconActive': {
            transform: 'none',
          },

          '& .MuiRating-iconEmpty': {
            color: colors.rating.large.color,
            backgroundColor: colors.rating.large.bg,
          },

          '& .MuiRating-iconFilled': {
            color: colors.rating.large.activeColor,
            backgroundColor: colors.rating.large.activeBg,
          },

          '& .MuiRating-icon': {
            padding: 16,
            borderRadius: 3,
            boxShadow: `0px 1px 3px ${colors.rating.large.shadow}`,
            border: `1px solid ${colors.rating.large.border}`,
          },

          '&:not(:last-of-type)': {
            '& .MuiRating-icon': {
              marginRight: '15px',
            },
          },
        },
      },
      iconEmpty: {
        color: colors.rating.default,
      },
    },
    // PAGINATION
    MuiPagination: {
      root: {
        margin: '70px 0',

        [breakpoints.down('xs')]: {
          margin: '35px 0',
        },
      },
      ul: {
        justifyContent: 'center',
      },
    },
    MuiPaginationItem: {
      root: {
        fontSize: '1.2rem',
        lineHeight: '1.2rem',
        fontWeight: 600,
        height: '30px',
        minWidth: '30px',
        margin: '0 4px',
      },
      rounded: {
        borderRadius: '2px',
      },
      icon: {
        fontSize: '1.6rem',
      },
      page: {
        '&.Mui-disabled': {
          opacity: 1,
          color: LIGHTGREY,
        },
      },
      textSecondary: {
        '&:hover': {
          backgroundColor: colors.secondary,
          color: colors.primaryText,
        },
        '&.Mui-selected': {
          boxShadow: `0px 6px 10px ${rgba(BLACK, 0.1)}`,
        },
      },
    },
    MuiSkeleton: {
      root: {},
      text: {
        borderRadius: '6.5px',
      },
    },

    MuiContainer: {
      fixed: {
        paddingLeft: 15,
        paddingRight: 15,
        maxWidth: '1160px !important',

        [breakpoints.up('md')]: {
          paddingLeft: 30,
          paddingRight: 30,
        },
      },
    },

    // BREADCRUMBS
    MuiBreadcrumbs: {
      root: {
        paddingTop: 20,
        fontSize: '1.4rem',
        lineHeight: '1.6rem',
        color: colors.text,

        '& .MuiLink-root': {
          color: colors.breadcrumbs.link,
          transition: 'color 0.3s ease',

          '&:hover': {
            color: colors.primary,
          },
        },
      },

      separator: {
        marginRight: 10,
        marginLeft: 10,
        color: colors.breadcrumbs.link,
        fontSize: '0.8rem',
        padding: 3,
      },

      li: {
        '&::first-letter': {
          textTransform: 'capitalize',
        },
      },
    },

    // SWITCH
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 0,
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(16px)',
          color: WHITE,
          '& + $track': {
            backgroundColor: colors.primary,
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${LIGHTGREY}`,
        backgroundColor: LIGHTGREY,
        opacity: 1,
        transition: 'background-color, color',
      },
      checked: {},
      colorPrimary: {
        '&$checked': {
          transform: 'translateX(16px)',
          color: WHITE,
          '& + $track': {
            backgroundColor: colors.primary,
            opacity: 1,
            border: 'none',
          },
        },
      },
    },
  },
  // Change default props
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'p',
        body2: 'p',
      },
    },
    MuiAppBar: {
      color: 'inherit',
    },
    //   MuiInputLabel: {
    //     shrink: true,
    //   },
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiFormControlLabel: {
      className: 'noselect',
    },
    MuiButton: {
      size: 'large',
      variant: 'contained',
    },
    MuiBadge: {
      overlap: 'rectangular',
    },
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true,
    },
    MuiSelect: {
      variant: 'outlined',
      fullWidth: true,
      IconComponent: KeyboardArrowDown,
    },
    MuiCheckbox: {
      icon: <i className="icon-correct-symbol default" />,
      checkedIcon: <i className="icon-correct-symbol" />,
      disableRipple: true,
      color: 'primary',
    },
    MuiMenu: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      getContentAnchorEl: null,
    },
    MuiLink: {
      underline: 'none',
      variant: 'button',
    },
    MuiFormControl: {
      fullWidth: true,
    },
    MuiRating: {
      icon: <i className="icon-star-solid" />,
    },
    MuiPagination: {
      color: 'secondary',
      shape: 'rounded',
    },
    MuiTooltip: {
      arrow: true,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Poppins', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    h1: {
      fontSize: '6rem',
      lineHeight: '6.4rem',
      fontWeight: 400,

      [breakpoints.down('xs')]: {
        fontSize: '4.6rem',
        lineHeight: '5rem',
      },
    },
    h2: {
      fontSize: '4.2rem',
      lineHeight: '4.6rem',
      fontWeight: 400,

      [breakpoints.down('xs')]: {
        fontSize: '3.2rem',
        lineHeight: '3.8rem',
      },

      '& span[role="img"]': {
        fontSize: '3.2rem',
      },
    },
    h3: {
      fontSize: '3.5rem',
      lineHeight: '4rem',
      fontWeight: 600,

      [breakpoints.down('xs')]: {
        fontSize: '3rem',
        lineHeight: '3rem',
      },
    },
    h4: {
      fontSize: '3rem',
      lineHeight: '3.4rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.9rem',
      lineHeight: '2.4rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.8rem',
      lineHeight: '2.2rem',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '1.8rem',
      lineHeight: '2rem',
      fontWeight: 300,
    },
    subtitle2: {
      fontSize: '1.8rem',
      lineHeight: '2.4rem',
      fontWeight: 300,
    },
    body1: {
      fontSize: '1.8rem',
      lineHeight: '3rem',
      fontWeight: 300,
    },
    body2: {
      fontSize: '1.8rem',
      lineHeight: '3rem',
      fontWeight: 500,
    },
  },
});

export const createNewTheme = (themeConsts = {}) => {
  const fontFamily = themeConsts.primaryFont ? themeConsts.primaryFont.split(', ') : '';

  /*
    primary (HEX string)
    secondary (HEX string)
    primaryButtonTextColor (HEX string)
    secondaryButtonTextColor (HEX string)
    buttonHoverBackground (HEX string)
    background (HEX string)
    inputBackground (HEX string)
    primaryTextColor (HEX string)
    secondarytextcolor (HEX string)
    primaryFont (string divided by , if you want provide a list; For example "'Barlow', sans-serif")
    squareCorners (bool - true|false)
    error (HEX string)
    warning, (HEX string)
    homeLeft, (HEX string)
    homeLeftText, (HEX string)
    homeRight, (HEX string)
    homeRightText, (HEX string)
    subheaderBackground, (HEX string)
    subheaderTextColor, (HEX string)
    showMovieCardHoverBg, (HEX string)
    showMovieCardHoverText, (HEX string)
    showMovieCardHoverTextSecondary, (HEX string)
    bundleResultsBorder, (HEX string)
    freeTrialBg, (HEX string)
    freeTrialText, (HEX string)
    markerText, (HEX string)
  */

  return {
    ...theme,
    partnerStyles: true,
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: themeConsts.primary || theme.palette.primary.main,
        contrastText: themeConsts.primaryButtonTextColor || theme.palette.primary.contrastText,
        dark: themeConsts.buttonHoverBackground || theme.palette.primary.dark,
      },
      secondary: {
        ...theme.palette.secondary,
        main: themeConsts.secondary || theme.palette.secondary.main,
        contrastText: themeConsts.secondaryButtonTextColor || theme.palette.secondary.contrastText,
        dark: darken(0.1, themeConsts.secondary || theme.palette.secondary.main),
      },
      background: {
        ...theme.palette.background,
        default: themeConsts.background || theme?.palette?.background?.default,
        contentHero: themeConsts.background || theme.palette.background.contentHero,
      },
      text: {
        ...theme.palette.text,
        primary: themeConsts.primaryTextColor || theme.palette.text.primary,
        secondary: themeConsts.secondarytextcolor || theme.palette.text.secondary,
      },
      success: {
        ...theme.palette.success,
        main: themeConsts.primary || theme.palette.primary.main,
        contrastText: themeConsts.primaryTextColor || theme.palette.text.primary,
      },
      error: {
        ...theme.palette.error,
        main: themeConsts.error || theme.palette.error.main,
        contrastText: themeConsts.primaryTextColor || theme.palette.text.primary,
      },
      warning: {
        ...theme.palette.warning,
        main: themeConsts.warning || theme.palette.warning.main,
        contrastText: themeConsts.primaryTextColor || theme.palette.text.primary,
      },
      home: {
        ...theme.palette.home,
        left: themeConsts.homeLeft || theme.palette.home.left,
        leftText: themeConsts.homeLeftText || theme.palette.home.leftText,
        right: themeConsts.homeRight || theme.palette.home.right,
        rightText: themeConsts.homeRightText || theme.palette.home.rightText,
      },
      subheader: {
        ...theme.palette.subheader,
        background: themeConsts.subheaderBackground || theme.palette.subheader.background,
        color:
          themeConsts.subheaderTextColor ||
          themeConsts.primaryTextColor ||
          theme.palette.subheader.color,
      },
      showMovieCard: {
        ...theme.palette.showMovieCard,
        hoverBg: themeConsts.showMovieCardHoverBg || theme.palette.showMovieCard.hoverBg,
        hoverText: themeConsts.showMovieCardHoverText || theme.palette.showMovieCard.hoverText,
        hoverTextSecondary:
          themeConsts.showMovieCardHoverTextSecondary ||
          theme.palette.showMovieCard.hoverTextSecondary,
      },
      bundle: {
        ...theme.palette.bundle,
        border: themeConsts.bundleResultsBorder || theme.palette.bundle.border,
        highlighted: themeConsts.bundleResultsBorder || theme.palette.bundle.highlighted,
      },
      appCard: {
        freeTrialBg: themeConsts.freeTrialBg,
        freeTrialText: themeConsts.freeTrialText,
      },
    },
    overrides: {
      ...theme.overrides,
      MuiBreadcrumbs: {
        ...theme.overrides.MuiBreadcrumbs,
        root: {
          ...theme.overrides.MuiBreadcrumbs.root,

          '& .MuiLink-root': {
            ...theme.overrides.MuiBreadcrumbs.root['& .MuiLink-root'],

            '&:hover': {
              ...theme.overrides.MuiBreadcrumbs.root['& .MuiLink-root']['&:hover'],
              color: themeConsts.primary || theme.palette.primary.main,
            },
          },
        },
      },
      MuiInputBase: {
        ...theme.overrides.MuiInputBase,
        root: {
          ...theme.overrides.MuiInputBase.root,
          backgroundColor: themeConsts.inputBackground || 'transparent',
        },
      },
      MuiFilledInput: {
        ...theme.overrides.MuiFilledInput,
        root: {
          ...theme.overrides.MuiFilledInput.root,
          backgroundColor: themeConsts.inputBackground || 'transparent',
        },
      },
      MuiButton: {
        ...theme.overrides.MuiButton,
        root: {
          ...theme.overrides.MuiButton.root,
          borderRadius: themeConsts.squareCorners
            ? '0'
            : theme.overrides.MuiButton.root.borderRadius,

          '&.sizeExtraLarge': {
            ...theme.overrides.MuiButton.root['&.sizeExtraLarge'],
            borderRadius: themeConsts.squareCorners
              ? '0'
              : theme.overrides.MuiButton.root['&.sizeExtraLarge'].borderRadius,
          },
        },
        sizeLarge: {
          ...theme.overrides.MuiButton.sizeLarge,
          borderRadius: themeConsts.squareCorners
            ? '0'
            : theme.overrides.MuiButton.sizeLarge.borderRadius,
        },
        outlinedPrimary: {
          ...theme.overrides.MuiButton.outlinedPrimary,
          borderColor: themeConsts.primary || theme.overrides.MuiButton.outlinedPrimary.borderColor,
        },
        outlinedSecondary: {
          ...theme.overrides.MuiButton.outlinedSecondary,
          border: `0.2rem solid ${themeConsts.secondaryButtonTextColor ||
            theme.palette.secondary.contrastText}`,
          color: themeConsts.secondaryButtonTextColor || theme.palette.secondary.contrastText,
        },
        contained: {
          ...theme.overrides.MuiButton.contained,
          '&.purple': {
            backgroundColor: themeConsts.primary || theme.palette.primary.main,
            color: themeConsts.primaryButtonTextColor || theme.palette.primary.contrastText,
          },
        },
      },
      MuiCheckbox: {
        ...theme.overrides.MuiCheckbox,
        root: {
          ...theme.overrides.MuiCheckbox.root,
          '& .icon-correct-symbol': {
            ...theme.overrides.MuiCheckbox.root['& .icon-correct-symbol'],
            '&.default:before': {
              ...theme.overrides.MuiCheckbox.root['& .icon-correct-symbol']['&.default:before'],
              borderColor: themeConsts.primary || theme.palette.primary.main,
            },

            '&:before': {
              ...theme.overrides.MuiCheckbox.root['& .icon-correct-symbol']['&:before'],
              border: `1px solid ${themeConsts.primary || theme.palette.primary.main}`,
              borderRadius: themeConsts.squareCorners
                ? '0'
                : theme.overrides.MuiCheckbox.root['& .icon-correct-symbol']['&:before']
                    .borderRadius,
            },
          },
        },
        colorPrimary: {
          ...theme.overrides.MuiCheckbox.colorPrimary,
          '&.Mui-checked': {
            ...theme.overrides.MuiCheckbox.colorPrimary['&.Mui-checked'],
            color: themeConsts.primaryButtonTextColor || theme.palette.primary.contrastText,

            '& .icon-correct-symbol': {
              ...theme.overrides.MuiCheckbox.colorPrimary['&.Mui-checked'][
                '& .icon-correct-symbol'
              ],
              '&:before': {
                ...theme.overrides.MuiCheckbox.colorPrimary['&.Mui-checked'][
                  '& .icon-correct-symbol'
                ]['&:before'],
                backgroundColor: themeConsts.primary || theme.palette.primary.main,
              },
            },

            '& + .MuiFormControlLabel-label': {
              ...theme.overrides.MuiCheckbox.colorPrimary['&.Mui-checked'][
                '& + .MuiFormControlLabel-label'
              ],
              color: themeConsts.primary || theme.palette.primary.main,
            },
          },
        },
      },
      MuiChip: {
        ...theme.overrides.MuiChip,
        root: {
          ...theme.overrides.MuiChip.root,
          backgroundColor: themeConsts.primary || theme.overrides.MuiChip.root.backgroundColor,
          borderRadius: themeConsts.squareCorners ? '0' : theme.overrides.MuiChip.root.borderRadius,
        },
        clickable: {
          ...theme.overrides.MuiChip.clickable,
          '&:hover, &:focus': {
            ...theme.overrides.MuiChip.clickable['&:hover, &:focus'],
            backgroundColor:
              themeConsts.primary ||
              theme.overrides.MuiChip.clickable['&:hover, &:focus'].backgroundColor,
          },
        },
      },
      MuiInputAdornment: {
        ...theme.overrides.MuiInputAdornment,
        root: {
          ...theme.overrides.MuiInputAdornment.root,
          '&.success': {
            color: themeConsts.primary,
          },
        },
      },
      MuiListItem: {
        ...theme.overrides.MuiListItem,
        root: {
          '&.Mui-selected, &.Mui-selected:hover, &.MuiListItem-button:hover': {
            ...theme.overrides['&.Mui-selected, &.Mui-selected:hover, &.MuiListItem-button:hover'],
            color: themeConsts.primary,
            '&.secondary': {
              backgroundColor: themeConsts.background || theme?.palette?.background?.default,
            },
          },
        },
      },
      MuiPaginationItem: {
        ...theme.overrides.MuiPaginationItem,
        root: {
          ...theme.overrides.MuiPaginationItem.root,
          color: themeConsts.primary || theme.overrides.MuiPaginationItem.root.color,
          backgroundColor: themeConsts.primaryButtonTextColor || 'transparent',
        },
        textSecondary: {
          ...theme.overrides.MuiPaginationItem.textSecondary,
          '&:hover': {
            ...theme.overrides.MuiPaginationItem.textSecondary['&:hover'],
            backgroundColor:
              themeConsts.primary ||
              theme.overrides.MuiPaginationItem.textSecondary['&:hover'].backgroundColor,
            color:
              themeConsts.primaryButtonTextColor ||
              theme.overrides.MuiPaginationItem.textSecondary['&:hover'].color,
          },
          '&.Mui-selected': {
            ...theme.overrides.MuiPaginationItem.textSecondary['&.Mui-selected'],
            backgroundColor:
              themeConsts.primary ||
              theme.overrides.MuiPaginationItem.textSecondary['&.Mui-selected'].backgroundColor,
            color:
              themeConsts.primaryButtonTextColor ||
              theme.overrides.MuiPaginationItem.textSecondary['&.Mui-selected'].color,
          },
        },
      },
      MuiIconButton: {
        ...theme.overrides.MuiIconButton,
        colorSecondary: {
          ...(theme.overrides.MuiIconButton?.colorSecondary || {}),
          ...(themeConsts.primary && themeConsts.secondary
            ? {
                color: themeConsts.secondary,
                backgroundColor: themeConsts.primary,
                '&:hover': {
                  ...(theme.overrides.MuiIconButton?.colorSecondary?.['&:hover'] || {}),
                  backgroundColor: rgba(themeConsts.primary, 0.04),
                },
              }
            : {}),
        },
      },
      MuiRating: {
        ...theme.overrides.MuiRating,
        root: {
          ...theme.overrides.MuiRating.root,
          color: themeConsts.primary || theme.overrides.MuiRating.root.color,

          '&.sizeLarge': {
            ...theme.overrides.MuiRating.root['&.sizeLarge'],
            '& .MuiRating-iconEmpty': {
              ...theme.overrides.MuiRating.root['&.sizeLarge']['& .MuiRating-iconEmpty'],
              color:
                themeConsts.primary ||
                theme.overrides.MuiRating.root['&.sizeLarge']['& .MuiRating-iconEmpty'].color,
            },

            '& .MuiRating-iconFilled': {
              ...theme.overrides.MuiRating.root['&.sizeLarge']['& .MuiRating-iconFilled'],
              color:
                themeConsts.primaryButtonTextColor ||
                theme.overrides.MuiRating.root['&.sizeLarge']['& .MuiRating-iconFilled'].color,
              backgroundColor:
                themeConsts.primary ||
                theme.overrides.MuiRating.root['&.sizeLarge']['& .MuiRating-iconFilled']
                  .backgroundColor,
            },

            '& .MuiRating-icon': {
              ...theme.overrides.MuiRating.root['&.sizeLarge']['& .MuiRating-icon'],
              border: `1px solid ${themeConsts.primary || colors.rating.large.border}`,
            },
          },
        },
      },
      MuiTab: {
        ...theme.overrides.MuiTab,
        root: {
          ...theme.overrides.MuiTab.root,
          borderRadius: themeConsts.squareCorners ? '0' : theme.overrides.MuiTab.root.borderRadius,
          backgroundColor: themeConsts.primary || theme.overrides.MuiTab.root.backgroundColor,
          color: themeConsts.primaryButtonTextColor || theme.overrides.MuiTab.root.color,
          '&$selected': {
            ...theme.overrides.MuiTab.root['&$selected'],
            backgroundColor:
              themeConsts.primary || theme.overrides.MuiTab.root['&$selected'].backgroundColor,
            color:
              themeConsts.primaryButtonTextColor || theme.overrides.MuiTab.root['&$selected'].color,
          },
        },
        textColorInherit: {
          color: themeConsts.primaryButtonTextColor || theme.palette.primary.contrastText,
        },
      },
    },
    typography: {
      ...theme.typography,
      fontFamily: fontFamily || theme.typography.fontFamily,
      body1: { ...theme.typography.body1, fontFamily: fontFamily || theme.typography.fontFamily },
      body2: { ...theme.typography.body2, fontFamily: fontFamily || theme.typography.fontFamily },
      button: { ...theme.typography.button, fontFamily: fontFamily || theme.typography.fontFamily },
      caption: {
        ...theme.typography.caption,
        fontFamily: fontFamily || theme.typography.fontFamily,
      },
      subtitle1: {
        ...theme.typography.subtitle1,
        fontFamily: fontFamily || theme.typography.fontFamily,
      },
      subtitle2: {
        ...theme.typography.subtitle2,
        fontFamily: fontFamily || theme.typography.fontFamily,
      },
      overline: {
        ...theme.typography.overline,
        fontFamily: fontFamily || theme.typography.fontFamily,
      },
      h1: { ...theme.typography.h1, fontFamily: fontFamily || theme.typography.fontFamily },
      h2: { ...theme.typography.h2, fontFamily: fontFamily || theme.typography.fontFamily },
      h3: { ...theme.typography.h3, fontFamily: fontFamily || theme.typography.fontFamily },
      h4: { ...theme.typography.h4, fontFamily: fontFamily || theme.typography.fontFamily },
      h5: { ...theme.typography.h5, fontFamily: fontFamily || theme.typography.fontFamily },
      h6: { ...theme.typography.h6, fontFamily: fontFamily || theme.typography.fontFamily },
    },
  };
};

export default theme;
