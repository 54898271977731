import styled from 'styled-components';
import { Typography, TextField, Button, Link } from '@material-ui/core';
import { Form } from 'formik';
import { rgba } from 'polished';

// CONSTANTS
import colors, { SHARK, ALTO, WHITE, BLACK } from '@/constants/colors';

// UTILITY
import { breakpoints } from '@/utility/breakpoints';

export const FooterWrap = styled.div`
  .footer-wrapper {
    &__main {
      border-bottom: 1px solid ${WHITE};
      padding-bottom: 70px;
    }

    &__logo {
      a {
        padding: 0;
      }

      svg {
        width: 208px;
        height: auto;
      }

      margin-bottom: 20px;
    }
  }

  .get-app {
    &__qr svg {
      padding: 12px;
      background-color: ${WHITE};
    }

    &__text {
      margin-top: 5px;
      color: ${colors.footer.menu.link};
    }
  }

  &.footer-wrapper {
    &--partner {
      padding: 0;
    }
  }

  &:not(.footer-wrapper--partner) {
    .footer-wrapper {
      &__add-info {
        padding-bottom: 0;
      }
    }
  }
`;

export const SectionTwo = styled.div`
  && {
    padding: 20px 0;
  }

  .footer-iframe {
    flex-wrap: nowrap;

    ${breakpoints.down('xs')} {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  a.footer-link {
    font-size: inherit;
  }

  .footer-contact {
    ${breakpoints.down('sm')} {
      text-align: center;
    }
  }
`;

export const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${breakpoints.down('sm')} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const ContactStyled = styled.div`
  color: ${colors.footer.menu.link};

  a {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: ${p => p.flexDirection || 'row'};
  justify-content: flex-start;
  align-items: flex-start;

  ${breakpoints.down('sm')} {
    width: 100%;
    justify-content: center;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
`;

export const SubscribeBlock = styled(Block)`
  ${breakpoints.down('sm')} {
    align-items: center;
  }
`;

export const ListBlock = styled(Block)`
  ${breakpoints.down('sm')} {
    margin-top: 50px;
    justify-content: center;
  }

  ${breakpoints.down('xs')} {
    margin-top: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Rights = styled(Typography)`
  color: ${colors.footer.menu.link};
`;

export const SubscriptionWrapper = styled.div`
  margin-top: 60px;

  ${breakpoints.down('sm')} {
    margin-top: 30px;
  }
`;

export const Label = styled(Typography)`
  color: ${ALTO};
  margin-bottom: 10px;
`;

export const FormStyled = styled(Form)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const InputStyled = styled(TextField)`
  && {
    .MuiInputBase-root {
      border-radius: 0;
      background-color: ${SHARK};

      height: 56px;
    }

    fieldset {
      border: none;
    }

    .MuiInputBase-input {
      padding: 2rem;
      color: ${WHITE};
      border-radius: 0;

      &::-webkit-input-placeholder {
        color: ${colors.footer.menu.link};
      }
    }
  }
`;

export const ButtonStyled = styled(Button)`
  && {
    height: 56px;
    border-radius: 0;

    &:disabled {
      background-color: ${rgba(WHITE, 0.15)};
    }
  }
`;

export const ListWrapper = styled.div`
  min-width: 200px;
  margin-right: 30px;

  &:last-of-type {
    margin-right: 0;
  }

  ${breakpoints.down('sm')} {
    min-width: 160px;
  }

  ${breakpoints.down('xs')} {
    width: 100%;
    margin: 0;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const CollapseTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
`;

export const ListContainer = styled.div`
  margin-top: 25px;

  ${breakpoints.down('xs')} {
    margin-top: 15px;
  }
`;

export const ListItem = styled(Link)`
  && {
    display: block;

    color: ${colors.footer.menu.link};
    line-height: 1.9rem;

    margin-bottom: 17px;

    transition: color 0.3s ease;

    &.active,
    &:hover {
      color: ${p => p.theme.palette.primary.main};
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const TermsAndPolicy = styled.div`
  color: ${colors.footer.termsPolicy.text};
  white-space: normal;
  word-break: break-word;

  ${breakpoints.down('sm')} {
    text-align: center;
  }
`;

export const TermsAndPolicyLink = styled(Link)`
  && {
    transition: color 0.3s ease;

    color: ${colors.footer.termsPolicy.link};
    font-size: inherit;

    &:hover {
      color: ${p => p.theme.palette.primary.main};
    }
  }
`;

export const Version = styled.div`
  position: absolute;
  bottom: 1px;
  right: 1px;

  color: ${BLACK};
`;
