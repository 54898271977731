import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';

// CONSTANTS
import { ATHENS_GRAY, GRAY_SUIT, MAIN_BLACK, WHITE } from '@/constants/colors';

// UTILITY
import breakpoints from '@/utility/breakpoints';

export const BalanceButton = styled(Button)`
  padding: 10px;

  min-width: 40px;
  min-height: 40px;

  border-radius: 50%;

  background-color: ${ATHENS_GRAY};
  border: 1px solid ${GRAY_SUIT};
  color: ${MAIN_BLACK};

  &:hover,
  &:active {
    background-color: ${MAIN_BLACK};
    border-color: ${MAIN_BLACK};
    color: ${WHITE};
  }

  svg {
    font-size: 2rem;
  }

  .balance {
    display: none;
  }

  ${breakpoints.up(1440)} {
    width: 100% !important;

    border-radius: 100px;

    .balance {
      display: block;

      margin-left: 14px;
      line-height: 2.4rem;
    }
  }
`;

export const BalanceContent = styled.div`
  .content {
    &__title {
      font-weight: 600;
      line-height: 2.1rem;

      .balance {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    &__subtitle {
      margin: 8px 0;

      font-size: 1.3rem;
      line-height: 1.5rem;
    }

    &__button {
      margin-top: 12px;
      font-size: 1.2rem;
    }

    ${breakpoints.up('md')} {
      &__title {
        font-size: 2rem;
        line-height: 3rem;
      }

      &__subtitle {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }

      &__button {
        font-size: 1.4rem;

        .balance {
          display: flex;
        }
      }
    }
  }

  .virtual-card {
    &__title {
      padding: 12px;

      .MuiTypography-root {
        font-size: 1.6rem;
        line-height: 1.6rem;
      }
    }

    .billing__icon-wrap {
      display: none;
    }
  }
`;

export const BalanceTooltipPopper = styled(Popper)`
  .MuiTooltip-tooltip {
    max-width: 360px;

    background-color: ${WHITE};
    color: ${MAIN_BLACK};
    border: 1px solid ${GRAY_SUIT};

    padding: 15px;

    ${breakpoints.up('md')} {
      max-width: 378px;
      padding: 24px 20px;
    }
  }
`;

export default null;
