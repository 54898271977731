import React from 'react';
import Icon from '@material-ui/core/Icon';

// ASSETS
import { ReactComponent as GoogleIconSvg } from '@/assets/images/icons/google-colored.svg';

function GoogleColoredIcon(props) {
  return <Icon {...props} component={GoogleIconSvg} />;
}

export default React.memo(GoogleColoredIcon);
