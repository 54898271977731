import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';

// UTILITY
import { externalLink } from '@/utility/analytics';

// STYLES
import { Container } from './styles';

const socialLinks = [
  {
    href: 'https://www.facebook.com/findmybundle',
    name: 'Facebook',
    icon: 'facebook',
  },
  {
    href: 'https://twitter.com/mybundletv',
    name: 'x',
    icon: 'x',
  },
  {
    href: 'https://www.instagram.com/mybundletv/',
    name: 'Instagram',
    icon: 'instagram',
  },
];

const SocialLinks = () => {
  const { partner } = useSelector(({ partner: partnerStore }) => ({
    partner: partnerStore.data,
  }));

  const handleExternalLink = data => {
    const properties = {
      location: 'footer',
      other: 'social link',
      href: data.href,
      partner: partner?.partner_slug,
      partner_id: partner?.partner_id,
    };

    externalLink(properties);
  };

  return (
    <Container className="social-links">
      {socialLinks.map(socialLink => {
        return (
          <li className="social-links__item" key={socialLink.name}>
            <Tooltip title={socialLink.name}>
              <a
                href={socialLink.href}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleExternalLink(socialLink)}
              >
                <i className={`icon-${socialLink.icon}`} />
              </a>
            </Tooltip>
          </li>
        );
      })}
    </Container>
  );
};

export default SocialLinks;
