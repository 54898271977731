import styled from 'styled-components';

// UTILITY
import { breakpoints } from '@/utility/breakpoints';

// COMPONENTS
import DialogCTA from '@/components/Dialogs/DialogCTA';

export const StyledDialog = styled(DialogCTA)`
  .MuiDialog-paper {
    overflow: hidden;
    max-width: 734px;
  }

  .dialog-cta {
    &__main-content {
      .dialog-cta__content {
        padding-right: 26px;
        padding-left: 26px;

        ${breakpoints.down('xs')} {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    &__image-wrapper {
      position: relative;
      margin: -60px -24px 20px;

      img {
        width: 100%;
      }

      ${breakpoints.down('xs')} {
        margin-bottom: 60px;
      }
    }
  }
`;

export default StyledDialog;
