import styled from 'styled-components';

// CONSTANTS
import { WHITE } from '@/constants/colors';

export const Container = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;

  .social-links {
    &__item {
      display: inline-block;
      font-size: 3rem;

      &:not(:last-of-type) {
        margin-right: 30px;
      }

      a {
        text-decoration: none;
        color: ${WHITE};
        display: inline-block;
      }
    }
  }
`;

export default Container;
