import * as Yup from 'yup';
import axios from 'axios';
import { USER_CHECK_UNIQUE_FIELD } from '@/constants/api';

// CONSTANTS
import * as validationConst from '@/constants/validation';

export default (t, data) => {
  return {
    first_name: Yup.string(),
    last_name: Yup.string(),
    nickname: Yup.string()
      .required(t('validation.usernameRequired'))
      .test('nickname', t('validation.userNameAlreadyTaken'), nickname => {
        if (nickname) {
          if (nickname && data.nickname !== nickname) {
            return axios
              .post(USER_CHECK_UNIQUE_FIELD, { field: 'nickname', value: nickname })
              .then(res => !res.data.exist)
              .catch(() => true);
          }
        }
        return true;
      }),
    email: Yup.string()
      .email(t('validation.emailNotValid'))
      .required(t('validation.emailRequired'))
      .test('email', t('validation.emailsAlreadyTaken'), email => {
        if (email) {
          const schema = Yup.string().email();
          if (schema.isValidSync(email) && data.email !== email) {
            return axios
              .post(USER_CHECK_UNIQUE_FIELD, { field: 'email', value: email })
              .then(res => !res.data.exist)
              .catch(() => true);
          }
        }
        return true;
      }),
    zipcode: Yup.string()
      .min(
        validationConst.MIN_ZIP_CODE_LENGTH,
        t('validation.minZipCodeLength', { length: validationConst.MIN_ZIP_CODE_LENGTH }),
      )
      .max(
        validationConst.MAX_ZIP_CODE_LENGTH,
        t('validation.maxZipCodeLength', { length: validationConst.MAX_ZIP_CODE_LENGTH }),
      ),
  };
};
