// CONSTANTS
import { getLink } from '@/utility/routes';
import {
  ROUTE_DISCOVER,
  ROUTE_WATCH_NEXT,
  ROUTE_HOW_IT_WORKS,
  ROUTE_FAQ,
  ROUTE_ABOUT,
  ROUTE_START,
  ROUTE_BLOG,
  ROUTE_NEWS,
  ROUTE_PROFILE_DETAILS,
  ROUTE_PROFILE_MY_RESULTS,
  ROUTE_PROFILE_MY_APPS_LIST,
  ROUTE_PROFILE_WN_RESULTS,
  ROUTE_SHOWS_AND_MOVIES,
  ROUTE_CAREERS,
  ROUTE_FREE_TV,
  ROUTE_MOBILE_APP,
  ROUTE_CONTACT_US,
  ROUTE_PROFILE_WATCHLISTS,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_AND_CONDITIONS,
  // ROUTE_WP_NFL,
  // ROUTE_BLACK_FRIDAY,
} from './routes';

const broadbandPartnersLink = 'https://mybundlepartners.com';
const streamingPartnersLink = 'https://mybundlepartners.com/streaming/';

// Main menu
export const headerList = [
  { label: 'common.menu.explore', route: ROUTE_DISCOVER },
  { label: 'common.menu.tvShowsAndMovies', route: ROUTE_SHOWS_AND_MOVIES },
  // { label: 'common.menu.nflSchedule', route: ROUTE_WP_NFL },
  // { label: 'common.menu.blackFriday', route: ROUTE_BLACK_FRIDAY },
  { label: 'common.menu.mobileApp', route: ROUTE_MOBILE_APP },
  {
    label: 'common.menu.more',
    route: ROUTE_ABOUT,
    openByDefault: true,
    submenu: [
      { label: 'common.menu.freeTV', route: ROUTE_FREE_TV },
      { label: 'common.menu.aboutUs', route: ROUTE_ABOUT },
      { label: 'common.menu.howItWorks', route: ROUTE_HOW_IT_WORKS },
      { label: 'common.menu.faq', route: ROUTE_FAQ },
      { label: 'common.menu.careers', route: ROUTE_CAREERS },
      { label: 'common.menu.ispPartnerWithUs', route: broadbandPartnersLink, type: 'external' },
      {
        label: 'common.menu.streamingPartnerWithUs',
        route: streamingPartnersLink,
        type: 'external',
      },
      { label: 'common.menu.insights', route: ROUTE_BLOG },
      { label: 'common.menu.news', route: ROUTE_NEWS },
      { label: 'common.menu.contactUs', route: ROUTE_CONTACT_US },
    ],
  },
];

export const myListMenu = [
  {
    label: 'common.menu.watchLists',
    route: ROUTE_PROFILE_WATCHLISTS,
    key: 'watchlists',
  },
  {
    label: 'common.menu.bingeResults',
    route: ROUTE_PROFILE_WN_RESULTS,
    key: 'binge-results',
  },
];

// Profile menu
export const profileList = [
  { label: 'common.menu.findShowMovie', route: ROUTE_SHOWS_AND_MOVIES, key: 'show-movies' },
  {
    label: 'common.menu.myList',
    route: ROUTE_PROFILE_WATCHLISTS,
    key: 'my-list',
    submenu: myListMenu,
    openByDefault: true,
  },
  {
    label: 'common.menu.myApps',
    route: getLink(ROUTE_PROFILE_MY_APPS_LIST, {
      content: 'have',
    }),
    key: 'my-apps',
    badge_key: 'apps_have',
  },
  { label: 'common.menu.myResults', route: ROUTE_PROFILE_MY_RESULTS, key: 'my-results' },
  { label: 'common.menu.profileDetails', route: ROUTE_PROFILE_DETAILS, key: 'profile-details' },
];

// Footer routes
export const footerRoutes = [
  { label: 'common.menu.aboutUs', route: ROUTE_ABOUT },
  { label: 'common.menu.helpCenter', route: 'https://help.mybundle.tv', type: 'external' },
  { label: 'common.menu.contactUs', route: ROUTE_CONTACT_US },
  { label: 'common.menu.careers', route: ROUTE_CAREERS },
  { label: 'common.menu.ourBlog', route: ROUTE_BLOG },
  { label: 'common.menu.privacyPolicy', route: ROUTE_PRIVACY_POLICY },
  { label: 'common.menu.termsAndConditions', route: ROUTE_TERMS_AND_CONDITIONS },
];

// Footer for partners
export const footerForPartners = [
  { label: 'common.menu.broadbandProviders', route: broadbandPartnersLink, type: 'external' },
  { label: 'common.menu.streamingServices', route: streamingPartnersLink, type: 'external' },
];

// Footer features
export const footerFeatures = [
  { label: 'common.menu.findMyBundle', route: ROUTE_START },
  { label: 'common.menu.explore', route: ROUTE_DISCOVER },
  { label: 'common.menu.discoverShowsAndMovies', route: ROUTE_SHOWS_AND_MOVIES },
  { label: 'common.menu.friendsRecommendations', route: ROUTE_WATCH_NEXT },
  { label: 'common.menu.liveTVApp', route: ROUTE_FREE_TV },
  { label: 'common.menu.mobileApp', route: ROUTE_MOBILE_APP },
];

export default headerList;
