import React, { createElement, memo } from 'react';
import PropTypes from 'prop-types';
import { parse, parseAttrs, SVG_ATTRIBUTES } from 'react-html5-parser';
import dompurify from 'dompurify';

// UTILITY
import bugsnagClient from '@/utility/bugsnagClient';

// HOC
import { BugsnagErrorBoundary } from '@/hoc/ErrorBoundary';

function ReactHtml({ component = 'div', className, components, children }) {
  if (!children) return null;

  return (
    <BugsnagErrorBoundary>
      {createElement(
        component,
        { className },
        parse(children, {
          components,
          attrsMap: parseAttrs(SVG_ATTRIBUTES),
          sanitize: html =>
            dompurify.sanitize(html, {
              ADD_TAGS: ['iframe', 'audio', 'style', 'svg', 'video'],
              ADD_ATTR: ['target'],
            }),
          onError: (error, html) => {
            bugsnagClient.notify(error, event => {
              event.addMetadata('htmlparser', { html });
            });
          },
        }),
      )}
    </BugsnagErrorBoundary>
  );
}

ReactHtml.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  components: PropTypes.object,
  component: PropTypes.string,
};

export default memo(ReactHtml);
