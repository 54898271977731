import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// UTILITY
import { trimCharacters } from '@/utility/common';
import setYupValidationSchema from '@/utility/validationSchema/validationSchema';
import { getS3ImageUrl } from '@/utility/content';

// COMPONENTS
import useActions from '@/hooks/useActions';
import { AppActions } from '@/store/actions';

// STYLES
import { StyledDialog } from './styles';

const dialogImg = getS3ImageUrl('/dialog-img.png');

const DialogConfirmPersonalData = () => {
  const [t] = useTranslation();

  const { dialogIsOpen, dialogProps, ipApiData, user } = useSelector(({ app, ipApi, auth }) => ({
    dialogIsOpen: app.confirmPDDialogIsOpen,
    dialogProps: app.confirmPDDialogProps,
    ipApiData: ipApi.data,
    user: auth.user || {},
  }));

  const [closeDialog] = useActions([
    () => AppActions.manageConfirmPDDialogState.action({ state: false, props: {} }),
  ]);

  const firstNameValue = dialogProps.first_name || user?.first_name || '';
  const lastNameValue = dialogProps.last_name || user?.last_name || '';
  const zipcodeValue = dialogProps.zipcode || ipApiData?.zip || '';

  const initialValues = {
    first_name: firstNameValue,
    last_name: lastNameValue,
    zipcode: zipcodeValue,
  };

  const handleChangeZipCode = (event, setFieldValue) => {
    const value = trimCharacters(event.target.value);

    setFieldValue('zipcode', value);
  };

  const handleValidate = (values, validationSchema) => {
    try {
      validateYupSchema(values, validationSchema, true);
    } catch (err) {
      return yupToFormErrors(err);
    }

    return {};
  };

  const handleSubmit = values => {
    if (dialogProps.callback) dialogProps.callback(values);

    closeDialog();
  };

  return (
    <StyledDialog
      open={dialogIsOpen}
      image={<img src={dialogImg} alt="hero" />}
      title={t('dialogConfirmPersonalData.title')}
      titleProps={{ className: 'text-bold', variant: 'h2', component: 'h4' }}
      subtitle={t('dialogConfirmPersonalData.subtitle')}
      maxWidth="md"
      hideCloseIcon
      hideCancelButton
      hideConfirmButton
    >
      <Formik
        initialValues={initialValues}
        validate={values =>
          handleValidate(values, setYupValidationSchema('confirmPersonalDataSchema', t))
        }
        enableReinitialize
        validateOnMount
        onSubmit={() => {}}
      >
        {({
          values,
          errors,
          touched,
          // handleChange,
          handleBlur,
          setFieldValue,
          isValid,
          isSubmitting,
        }) => {
          return (
            <>
              {/* <TextField
                className="m-b-15"
                label={t('inputs.firstName')}
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.first_name && !!errors.first_name}
                helperText={touched.first_name && errors.first_name}
                autoComplete="off"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                className="m-b-15"
                label={t('inputs.lastName')}
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.last_name && !!errors.last_name}
                helperText={touched.last_name && errors.last_name}
                autoComplete="off"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
              <TextField
                className="zipcodeinput m-b-25"
                label={t(`inputs.enterZipCode`)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className={Boolean(!errors.zipcode) && !!values.zipcode ? 'success' : ''}
                      position="start"
                    >
                      <i className="icon-check" />
                    </InputAdornment>
                  ),
                }}
                type="tel"
                name="zipcode"
                value={values.zipcode}
                onChange={e => handleChangeZipCode(e, setFieldValue)}
                onBlur={handleBlur}
                error={touched.zipcode && !!errors.zipcode}
                helperText={touched.zipcode && errors.zipcode}
              />
              <Button
                className={clsx('sizeExtraLarge dialog-cta__confirm-btn')}
                fullWidth
                color="primary"
                onClick={() => handleSubmit(values)}
                disabled={!isValid && !isSubmitting}
              >
                {t('buttons.submit')}
              </Button>
            </>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};

export default React.memo(DialogConfirmPersonalData);
