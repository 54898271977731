import axios from 'axios';
import { put, call, select, delay } from 'redux-saga/effects';

// CONSTANTS
import {
  USER_EDIT,
  USER_RESULTS,
  USER_APPS_SEARCH,
  USER_GET_APPS,
  USER_CHANGE_APP_PRICE,
  USER_GET_RECOMMENDATIONS,
  USER_UPDATE_FIRST_NAME,
  USER_GET_CONTENT_COUNT,
  USER_GET_RECOMMENDED_APPS,
  USER_CARDS,
  USER_STREAMING_REWARD_WIDGET_GET,
  USER_GET_IO_BY_UNIQUE_ID,
  USER_CHANGE_PASSWORD,
  USER_CARDS_SETUP_INTENT,
  USER_CARDS_GET_STATUS,
} from '@/constants/api';
import {
  addPMDefErrorMessage,
  addPMProcessingMessage,
  addPMRequirePMMessage,
  PaymentMethodStatus,
} from '@/constants/payments';

// UTILITY
import { getLink, getSerializedParams } from '@/utility/routes';
import { getPartnerSlug, showErrorNotification } from '@/utility/saga';
import getErrorMessage from '@/utility/errors';

// STORE
import { UserActions, NotifActions } from '@/store/actions';
import { AuthSelectors, OnboardingSelectors } from '@/store/selectors';
import { swimlanesGetSaga } from './swimlanes';

// LOCALIZATION
import { t } from '@/locale/i18n';

const {
  userEditProfile,
  userGetResults,
  userGetApps,
  userChangeAppPrice,
  userAppsAutocomplete,
  userGetRecommendations,
  userUpdateFirstName,
  userGetContentCount,
  userGetRecommendedApps,
  userAddCard,
  userAddPaymentMethod,
  retrievePaymentMethodStatus,
  userRemovePaymentMethod,
  getIOByUniqueId,
  userChangePassword,
  userGetSRWidgetData,
  userGetCards,
  userSetDefaultCard,
  userUpdateSRBalance,
} = UserActions;
const { pushSuccessNotificationAction, pushErrorNotificationAction } = NotifActions;

// params - payment_method_id
function* pollPaymentMethodStatus(params) {
  const delayTimeMs = 3000;
  const maxCountApiCalls = 40;
  let countApiCalls = 1;

  while (countApiCalls < maxCountApiCalls) {
    const { data: respData } = yield axios.get(USER_CARDS_GET_STATUS, {
      cache: { ignoreCache: true },
      params,
    });

    if (respData.status !== PaymentMethodStatus.PENDING) {
      return {
        status: respData.status,
        card: respData.card,
      };
    }

    countApiCalls += 1;

    yield delay(delayTimeMs);
  }

  return { status: PaymentMethodStatus.PENDING };
}

export function* userEditProfileSaga(action) {
  yield put(userEditProfile.start());

  const { data, callback } = action.payload;

  try {
    const { data: respData } = yield axios.post(USER_EDIT, data);

    yield put(
      userEditProfile.success({
        user: respData.user,
      }),
    );

    yield put(pushSuccessNotificationAction(t('notification.profileUpdateSuccess')));

    if (callback) callback();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(userEditProfile.fail());
  }
}

export function* userGetResultsSaga(action) {
  yield put(userGetResults.start());

  try {
    const { data: respData } = yield axios.get(USER_RESULTS, {
      params: action?.payload?.params || {},
    });

    yield put(userGetResults.success(respData.formSubmissions));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(userGetResults.fail());
  }
}

export function* userAppsAutocompleteSaga(action) {
  yield put(userAppsAutocomplete.start());

  const { params } = action.payload;
  const partner = yield call(getPartnerSlug);
  const serializedParams = getSerializedParams({ ...params, partner });

  try {
    const { data: respData } = yield axios.get(USER_APPS_SEARCH, serializedParams);

    yield put(userAppsAutocomplete.success({ data: respData.apps }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(userAppsAutocomplete.fail());
  }
}

export function* userGetAppsSaga(action) {
  yield put(userGetApps.start());

  const { params } = action.payload;

  try {
    const { data: respData } = yield axios.get(USER_GET_APPS, { params: params || {} });

    yield put(
      userGetApps.success({
        data: respData.data,
      }),
    );

    return respData.data;
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(userGetApps.fail());

    return null;
  }
}

export function* userChangeAppPriceSaga(action) {
  const { data } = action.payload;
  const app = data.apps[0];

  yield put(userChangeAppPrice.start({ id: app.id }));

  try {
    yield axios.post(USER_CHANGE_APP_PRICE, data);

    yield put(
      userChangeAppPrice.success({
        data: app,
      }),
    );

    yield put(pushSuccessNotificationAction(t('notification.successfullyUpdatedPrice')));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(userChangeAppPrice.fail({ id: app.id }));
  }
}

export function* userGetRecommendationsSaga(action) {
  yield put(userGetRecommendations.start());

  const { params } = action.payload;

  try {
    const { data: respData } = yield axios.get(USER_GET_RECOMMENDATIONS, { params: params || {} });

    yield put(
      userGetRecommendations.success({
        data: respData.data,
        meta: respData.meta,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(userGetRecommendations.fail());
  }
}

export function* userUpdateFirstNameSaga(action) {
  yield put(userUpdateFirstName.start());

  const { first_name } = action.payload;

  try {
    yield axios.post(USER_UPDATE_FIRST_NAME, { first_name });

    yield put(
      userUpdateFirstName.success({
        first_name,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(userUpdateFirstName.fail());
  }
}

export function* userGetContentCountSaga() {
  yield put(userGetContentCount.start());

  try {
    const { data: respData } = yield axios.get(USER_GET_CONTENT_COUNT, { ignoreCache: true });

    yield put(
      userGetContentCount.success({
        data: respData.content_count,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(userGetContentCount.fail());
  }
}

export function* userGetRecommendedAppsSaga() {
  yield put(userGetRecommendedApps.start());

  try {
    const { data: respData } = yield axios.get(USER_GET_RECOMMENDED_APPS);

    yield put(userGetRecommendedApps.success({ data: respData }));
  } catch (error) {
    yield put(pushErrorNotificationAction(error));

    yield put(userGetRecommendedApps.fail());
  }
}

export function* userGetCardsSaga(action) {
  yield put(userGetCards.start());

  const params = action?.payload || {};

  try {
    const { data: respData } = yield axios.get(USER_CARDS, { params });

    yield put(userGetCards.success(respData));
  } catch (error) {
    yield put(userGetCards.fail());

    yield put(pushErrorNotificationAction(error));
  }
}

export function* userSetDefaultCardSaga(action) {
  yield put(userSetDefaultCard.start());

  const { payment_method_id } = action.payload || {};

  try {
    yield axios.post(USER_CARDS, { payment_method_id, default: true });

    yield put(userSetDefaultCard.success({ payment_method_id }));

    yield put(pushSuccessNotificationAction(t('notification.setDefaultCardSuccess')));
  } catch (error) {
    yield put(userSetDefaultCard.fail());

    yield put(pushErrorNotificationAction(error));
  }
}

export function* userAddCardSaga(action) {
  yield put(userAddCard.start());

  const { stripe, card, zipcode, callback } = action.payload;

  try {
    const payment = yield call(() =>
      stripe
        .createPaymentMethod({
          card,
          type: 'card',
          billing_details: {
            address: {
              postal_code: zipcode,
            },
          },
        })
        .then(({ error, paymentMethod }) => {
          if (error) {
            throw error;
          }

          return paymentMethod;
        }),
    );

    const { data: respData } = yield axios.post(USER_CARDS, {
      default: 1,
      payment_method_id: payment.id,
    });

    yield put(userAddCard.success(respData));

    if (callback?.call) callback();
  } catch (error) {
    yield put(userAddCard.fail());

    yield put(pushErrorNotificationAction(error));
  }
}

export function* retrievePaymentMethodStatusSaga(action) {
  const { params, onSuccess, onPending = true } = action.payload;

  yield put(retrievePaymentMethodStatus.start());

  try {
    const { status, card } = yield call(pollPaymentMethodStatus, params);

    switch (status) {
      case PaymentMethodStatus.ADDED:
        yield put(retrievePaymentMethodStatus.success());

        if (onSuccess?.call) onSuccess({ card });
        break;
      case PaymentMethodStatus.PENDING:
        if (onPending?.call) onPending();

        yield put(
          retrievePaymentMethodStatus.fail({ warning: true, message: addPMProcessingMessage }),
        );
        break;
      case PaymentMethodStatus.FAILED:
      default:
        throw new Error(addPMDefErrorMessage);
    }

    return { status, card };
  } catch (error) {
    yield put(retrievePaymentMethodStatus.fail({ message: getErrorMessage(error) }));
  }
}

export function* userAddPaymentMethodSaga(action) {
  const { confirmSetup, defaultPM, onSuccess, onPending } = action.payload;

  yield put(userAddPaymentMethod.start());

  try {
    const { data: respData } = yield axios.get(USER_CARDS_SETUP_INTENT, {
      params: { default: defaultPM },
    });

    const setupIntent = yield call(confirmSetup, respData.clientSecret);

    switch (setupIntent.status) {
      case 'succeeded':
      case 'processing': {
        const paymentStatusData = yield call(retrievePaymentMethodStatusSaga, {
          payload: {
            params: {
              payment_method_id: setupIntent.payment_method,
            },
          },
        });

        if (paymentStatusData.status === PaymentMethodStatus.ADDED) {
          if (onSuccess?.call)
            onSuccess({
              card: paymentStatusData.card,
            });
        }

        if (paymentStatusData.status === PaymentMethodStatus.PENDING) {
          if (onPending?.call) onPending();

          yield put(userAddPaymentMethod.fail({ warning: true, message: addPMProcessingMessage }));

          break;
        }

        yield put(pushSuccessNotificationAction(t('notification.addPaymentMethodSuccess')));

        yield put(userAddPaymentMethod.success({ card: paymentStatusData.card }));

        break;
      }
      case 'requires_payment_method':
        throw new Error(addPMRequirePMMessage);
      default:
        throw new Error(addPMDefErrorMessage);
    }
  } catch (error) {
    yield put(userAddPaymentMethod.fail({ message: getErrorMessage(error) }));
  }
}

export function* userRemovePaymentMethodSaga(action) {
  yield put(userRemovePaymentMethod.start());

  const { card_id: card, last4 } = action.payload || {};

  try {
    yield axios.delete(USER_CARDS, { params: { card } });

    yield put(userRemovePaymentMethod.success({ card }));

    yield put(
      pushSuccessNotificationAction(t('notification.removePaymentMethodSuccess', { last4 })),
    );
  } catch (error) {
    yield put(userRemovePaymentMethod.fail());

    yield put(pushErrorNotificationAction(error));
  }
}

export function* userGetSRWidgetDataSaga() {
  yield put(userGetSRWidgetData.start());

  try {
    const { data: respData } = yield axios.get(USER_STREAMING_REWARD_WIDGET_GET, {
      cache: { ignoreCache: true },
    });

    yield put(userGetSRWidgetData.success(respData));
    yield put(userUpdateSRBalance.init({ balance: respData.card.balance }));
  } catch (error) {
    yield put(userGetSRWidgetData.fail());

    yield put(pushErrorNotificationAction(error));
  }
}

export function* userGetMyAppsCollectionsSaga() {
  const isAuth = yield select(AuthSelectors.getIsAuth);
  const selectedApps = yield select(OnboardingSelectors.getSelectedApps);

  const apps = isAuth ? [] : selectedApps;

  try {
    yield call(swimlanesGetSaga, {
      payload: { params: { page: 'my_apps', section: 1, app_ids: apps.map(app => app.id) } },
    });
  } catch (error) {
    yield call(showErrorNotification, error);
  }
}

export function* getIOByUniqueIdSaga(action) {
  yield put(getIOByUniqueId.start());

  const { unique_id } = action.payload;
  const url = getLink(USER_GET_IO_BY_UNIQUE_ID, { unique_id });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(getIOByUniqueId.success({ data: respData }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getIOByUniqueId.fail());
  }
}

export function* userChangePasswordSaga(action) {
  yield put(userChangePassword.start());

  const { data, callback } = action.payload;

  try {
    const { data: respData } = yield axios.post(USER_CHANGE_PASSWORD, data);

    yield put(pushSuccessNotificationAction(respData.message, { autoHideDuration: undefined }));

    yield put(userChangePassword.success());

    if (callback?.call) callback();
  } catch (error) {
    yield put(pushErrorNotificationAction(error));

    yield put(userChangePassword.fail());
  }
}
