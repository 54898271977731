import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import QRCode from 'react-qr-code';
import { useMediaQuery, Grid, Button, Link, Typography } from '@material-ui/core';

// CONSTANTS
import { LOCATIONS_FOOTER } from '@/constants/locations';
import { mobileAppDownloadLink, mobileAppDownloadLinkQR } from '@/constants/common';

// UTILITY
import { customAnalyticsEvent } from '@/utility/analytics';

// CONSTANTS
import { footerRoutes, footerForPartners, footerFeatures } from '@/constants/menus';
import { ROUTE_PRIVACY_POLICY, ROUTE_TERMS_AND_CONDITIONS } from '@/constants/routes';

// COMPONENTS
import Logo from '@/components/Logo';
import PoweredBy from '@/components/PoweredBy';
import NavLink from '@/components/NavLink';

// STYLES
import {
  FooterWrap,
  SectionTwo,
  ContainerStyled,
  Block,
  SubscribeBlock,
  ListBlock,
  Rights,
  TermsAndPolicy,
  TermsAndPolicyLink,
  Version,
  ContactStyled,
} from './styles';

// HELP
import List from './help/List';
import SocialLinks from './help/SocialLinks';

import packageJson from '../../../package.json';

const Footer = () => {
  const [t] = useTranslation();
  const { partner, partnerSettings } = useSelector(({ partner: partnerStore }) => ({
    partner: partnerStore.data,
    partnerSettings: partnerStore.data.settings || {},
  }));
  const upSm = useMediaQuery(({ breakpoints }) => breakpoints.up('sm'));
  let versionContent = null;

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      // eslint-disable-next-line no-console
      console.log(`v${packageJson.version}`);
    } else {
      versionContent = (
        <Version className="p5">
          <code>v{packageJson.version}</code>
        </Version>
      );
    }
  }, []);

  const showCollapse = Number(!upSm);

  const textOverwrites = partner.text_overwrites || {};
  const today = new Date();
  const year = today.getFullYear();
  const showPartnerFooter =
    !partnerSettings.is_fake || partnerSettings.show_partner_footer
      ? !!partner.partner_slug
      : false;
  const supportEmail = textOverwrites.customer_support_email || 'questions@mybundle.tv';
  const downloadLink = partnerSettings.mobile_app_download_link || mobileAppDownloadLink;
  const downloadLinkQR =
    partnerSettings.mobile_app_download_link || mobileAppDownloadLinkQR || mobileAppDownloadLink;

  const handleDownloadClick = () => {
    customAnalyticsEvent('Download App Clicked', { store: 'qr-clicked', link: downloadLink });
  };

  const termsAndPolicyContent = (
    <TermsAndPolicy>
      {t('footer.termsAndPolicy')}{' '}
      <TermsAndPolicyLink
        component={NavLink}
        to={ROUTE_PRIVACY_POLICY}
        rel="nofollow"
        location={LOCATIONS_FOOTER}
      >
        {t('footer.privacyPolicy')}
      </TermsAndPolicyLink>{' '}
      {t('common.and')}{' '}
      <TermsAndPolicyLink
        component={NavLink}
        to={ROUTE_TERMS_AND_CONDITIONS}
        rel="nofollow"
        location={LOCATIONS_FOOTER}
      >
        {t('footer.termsOfUse')}
      </TermsAndPolicyLink>
    </TermsAndPolicy>
  );

  return (
    <FooterWrap
      className={clsx(
        'footer-wrapper content-visibility--auto section section--deep-dark',
        showPartnerFooter && 'footer-wrapper--partner',
      )}
    >
      {!showPartnerFooter && (
        <ContainerStyled className="page-content footer-wrapper__main">
          <SubscribeBlock flexDirection="column">
            <div className="footer-wrapper__logo">
              <Logo location={LOCATIONS_FOOTER} type="light" />
            </div>
            {!!partnerSettings.is_fake && partner.phone ? (
              <div className="m-t-40">
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  href={`tel:${partner.phone}`}
                >
                  {t('buttons.call', {
                    phone: partner.phone,
                  })}
                </Button>
              </div>
            ) : (
              <div className="footer-wrapper__get-app get-app">
                <div className="get-app__qr">
                  <a
                    href={downloadLink}
                    onClick={handleDownloadClick}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <QRCode value={downloadLinkQR} size={147} />
                  </a>
                </div>
                <Typography className="get-app__text p4">{t('common.getMyBundleApp')}</Typography>
              </div>
            )}
          </SubscribeBlock>

          <ListBlock>
            {!partnerSettings.is_fake && (
              <List
                title={t('common.aboutMyBundle')}
                list={footerRoutes}
                showCollapse={showCollapse}
                t={t}
              />
            )}
            <List
              title={t('common.forPartners')}
              list={footerForPartners}
              showCollapse={showCollapse}
              t={t}
            />
            <List
              title={t('common.featuresItem')}
              list={footerFeatures}
              showCollapse={showCollapse}
              t={t}
            />
          </ListBlock>
        </ContainerStyled>
      )}
      <SectionTwo className="footer-wrapper__add-info section section--deep-dark p5">
        {showPartnerFooter ? (
          <div className="page-content">
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              alignItems="center"
              className="footer-iframe"
            >
              <Grid item>
                <PoweredBy />
              </Grid>
              <Grid item xs={12} sm="auto">
                {termsAndPolicyContent}
                <div className="footer-contact">
                  {t('footer.contact')}&nbsp;
                  <Link
                    className="footer-link"
                    href={`mailto:${supportEmail}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {supportEmail}
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <ContainerStyled className="page-content">
            <Block>
              <Rights className="p4">{t('footer.rights', { year })}</Rights>
            </Block>
            <Block>
              <ContactStyled className="footer-contact p4">
                {t('footer.contact')}&nbsp;<a href="mailto:help@mybundle.tv">help@mybundle.tv</a>{' '}
              </ContactStyled>
            </Block>
            <Block>
              <SocialLinks />
            </Block>
          </ContainerStyled>
        )}
      </SectionTwo>
      {versionContent}
    </FooterWrap>
  );
};

export default React.memo(Footer);
