import React from 'react';
import Icon from '@material-ui/core/Icon';

// ASSETS
import { ReactComponent as FacebookIconSvg } from '@/assets/images/icons/facebook-colored.svg';

function FacebookColoredIcon(props) {
  return <Icon {...props} component={FacebookIconSvg} />;
}

export default React.memo(FacebookColoredIcon);
