/*
 * ATTENTION!!! Read this rule before writing action types.
 *
 *  AUTH_                 -> Reducer
 *  _USER_                -> User role / Optional
 *  _ACTION_NAME_         -> Action
 */

// ANALYTICS
export const ANALYTICS_SEND_TO_FIVETRAN = 'ANALYTICS_SEND_TO_FIVETRAN';
export const ANALYTICS_SEND_TO_MAILCHIMP = 'ANALYTICS_SEND_TO_MAILCHIMP';

// APP
export const APP_SET_MAINTENANCE_MODE = 'APP_SET_MAINTENANCE_MODE';
export const APP_HEALTH_CHECK = 'APP_HEALTH_CHECK';
export const APP_EMAIL_UNSUBSCRIBE = 'APP_EMAIL_UNSUBSCRIBE';
export const APP_SET_PARTNER = 'APP_SET_PARTNER';
export const APP_MANAGE_PROF_DRAWER_STATE = 'APP_MANAGE_PROF_DRAWER_STATE';
export const APP_MANAGE_CONFIRM_ZIP_DIALOG_STATE = 'APP_MANAGE_CONFIRM_ZIP_DIALOG_STATE';
export const APP_SET_DEVICE_ORIENTATION = 'APP_SET_DEVICE_ORIENTATION';
export const APP_SET_HEADER_HEIGHT = 'APP_SET_HEADER_HEIGHT';
export const APP_CREATE_FEEDBACK = 'APP_CREATE_FEEDBACK';
export const APP_GET_SEARCH = 'APP_GET_SEARCH';
export const APP_GET_DICTIONARY = 'APP_GET_DICTIONARY';
export const APP_CONTACT_US_REQUEST_DEMO = 'APP_CONTACT_US_REQUEST_DEMO';
export const APP_GET_DOWNLOAD_APP_LINK_VIA_SMS = 'APP_GET_DOWNLOAD_APP_LINK_VIA_SMS';

// AUTH
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_CLEAN_ERROR = 'AUTH_LOGIN_CLEAN_ERROR';
export const AUTH0_LOGIN = 'AUTH0_LOGIN';
export const AUTH_SOCIAL_LOGIN = 'AUTH_SOCIAL_LOGIN';
export const AUTH_LOGIN_WITH_FIREBASE_TOKEN = 'AUTH_LOGIN_WITH_FIREBASE_TOKEN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REGISTER_USER = 'AUTH_REGISTER_USER';
export const AUTH_UPDATE_UNIQUE_ID = 'AUTH_UPDATE_UNIQUE_ID';
export const AUTH_DRAWER_OPEN = 'AUTH_DRAWER_OPEN';
export const AUTH_DRAWER_CLOSE = 'AUTH_DRAWER_CLOSE';
export const AUTH_RP_SEND_EMAIL = 'AUTH_RP_SEND_EMAIL';
export const AUTH_RP_UPDATE = 'AUTH_RP_UPDATE';
export const AUTH_DIALOG_MANAGE_STATE = 'AUTH_DIALOG_MANAGE_STATE';
export const AUTH_SET_ANONYMOUS_ID = 'AUTH_SET_ANONYMOUS_ID';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';

// AUTH THIRD PARTY
export const AUTH_REGISTER_CURIOSITY_STREAM_USER = 'AUTH_REGISTER_CURIOSITY_STREAM_USER';

// USER
export const USER_EDIT = 'USER_EDIT';
export const USER_GET_RESULTS = 'USER_GET_RESULTS';
export const USER_CLEAN_RESULTS = 'USER_CLEAN_RESULTS';
export const USER_APPS_AUTOCOMPLETE = 'USER_APPS_AUTOCOMPLETE';
export const USER_GET_APPS = 'USER_GET_APPS';
export const USER_GET_RECOMMENDED_APPS = 'USER_GET_RECOMMENDED_APPS';
export const USER_CHANGE_APP_PRICE = 'USER_CHANGE_APP_PRICE';
export const USER_UPDATE_APPS = 'USER_UPDATE_APPS';
export const USER_REMOVE_APP = 'USER_REMOVE_APP';
export const USER_GET_RECOMMENDATIONS = 'USER_GET_RECOMMENDATIONS';
export const USER_CLEAN_RECOMMENDATIONS = 'USER_CLEAN_RECOMMENDATIONS';
export const USER_UPDATE_FIRST_NAME = 'USER_UPDATE_FIRST_NAME';
export const USER_GET_CONTENT_COUNT = 'USER_GET_CONTENT_COUNT';
export const USER_GET_CARDS = 'USER_GET_CARDS';
export const USER_SET_DEFAULT_CARD = 'USER_SET_DEFAULT_CARD';
export const USER_ADD_CARD = 'USER_ADD_CARD';
export const USER_ADD_PAYMENT_METHOD = 'USER_ADD_PAYMENT_METHOD';
export const USER_RETRIEVE_PAYMENT_METHOD_STATUS = 'USER_RETRIEVE_PAYMENT_METHOD_STATUS';
export const USER_REMOVE_PAYMENT_METHOD = 'USER_REMOVE_PAYMENT_METHOD';
export const USER_UPDATE_CONTENT_COUNT = 'USER_UPDATE_CONTENT_COUNT';
export const USER_GET_MY_APPS_COLLECTIONS = 'USER_GET_MY_APPS_COLLECTIONS';
export const USER_GET_IO_BY_UNIQUE_ID = 'USER_GET_IO_BY_UNIQUE_ID';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';

// STREAMING REWARD
export const USER_STREAMING_REWARD_GET = 'USER_STREAMING_REWARD_GET';
export const USER_STREAMING_REWARD_WIDGET_GET = 'USER_STREAMING_REWARD_WIDGET_GET';
export const USER_STREAMING_REWARD_BALANCE_UPDATE = 'USER_STREAMING_REWARD_BALANCE_UPDATE';

// PAYMENT HISTORY
export const USER_PAYMENT_HISTORY_GET = 'USER_PAYMENT_HISTORY_GET';
export const USER_PAYMENT_HISTORY_CLEAR = 'USER_PAYMENT_HISTORY_CLEAR';

// NOTIFICATION
export const NOTIFICATION_POP_NOTIFY = 'NOTIFICATION_POP_NOTIFY';
export const NOTIFICATION_PUSH_NOTIFY = 'NOTIFICATION_PUSH_NOTIFY';
export const NOTIFICATION_SET_OPTIONS = 'NOTIFICATION_SET_OPTIONS';
export const NOTIFICATION_CLEAR_OPTIONS = 'NOTIFICATION_CLEAR_OPTIONS';

// PAGES
export const PAGES_GET_PAGES_CONTENT = 'PAGES_GET_PAGES_CONTENT';
export const PAGES_GET_SWIMLANES = 'PAGES_GET_SWIMLANES';

// WIZARD
export const WIZARD_GET_UNIQUE_ID = 'WIZARD_GET_UNIQUE_ID';
export const WIZARD_GET_SKIP_QUESTIONS = 'WIZARD_GET_SKIP_QUESTIONS';
export const WIZARD_RESET_SKIP_QUESTIONS = 'WIZARD_RESET_SKIP_QUESTIONS';
export const WIZARD_ASYNC_INTERNET_OPTIONS = 'WIZARD_ASYNC_INTERNET_OPTIONS';

// PARTNER
export const PARTNER_GET = 'PARTNER_GET';
export const PARTNER_CLEAN = 'PARTNER_CLEAN';
export const PARTNER_BY_DOMAIN = 'PARTNER_BY_DOMAIN';
export const PARTNER_INTERNET_OFFER_GET = 'PARTNER_INTERNET_OFFER_GET';
export const PARTNER_INTERNET_OFFER_CLAIM = 'PARTNER_INTERNET_OFFER_CLAIM';

// COUNTIES
export const COUNTIES_GET = 'COUNTIES_GET';
export const COUNTIES_GET_BY_ADDRESS = 'COUNTIES_GET_BY_ADDRESS';
export const COUNTIES_CLEAN_STATE = 'COUNTIES_CLEAN_STATE';

// CHANNEL DETAILS
export const CHANNEL_GET_DETAILS = 'CHANNEL_GET_DETAILS';
export const CHANNEL_GET_SWIMLANES = 'CHANNEL_GET_SWIMLANES';

// CHANNELS
export const CHANNELS_GET_FILTERS = 'CHANNELS_GET_FILTERS';
export const CHANNELS_GET = 'CHANNELS_GET';
export const CHANNELS_TOP_GET = 'CHANNELS_TOP_GET';
export const CHANNELS_SUBMIT_FORM = 'CHANNELS_SUBMIT_FORM';
export const CHANNELS_GET_FORM_DATA = 'CHANNELS_GET_FORM_DATA';
export const CHANNELS_AUTOCOMPLETE = 'CHANNELS_AUTOCOMPLETE';
export const CHANNELS_SET_AUTOCOMPLETE_DEF_DATA = 'CHANNELS_SET_AUTOCOMPLETE_DEF_DATA';
export const CHANNELS_CLEAN_FORM_DATA = 'CHANNELS_CLEAN_FORM_DATA';

// RESULTS
export const RESULTS_GET_RESULTS = 'RESULTS_GET_RESULTS';
export const RESULTS_ADD_EMAIL = 'RESULTS_ADD_EMAIL';
export const RESULTS_SEND_TOKEN = 'RESULTS_SEND_TOKEN';
export const RESULTS_APP_BY_IDS = 'RESULTS_APP_BY_IDS';
export const RESULTS_CHANNELS_BY_SERVICE_IDS = 'RESULTS_CHANNELS_BY_SERVICE_IDS';
export const CLEAR_RESULTS_CHANNELS_BY_SERVICE_IDS = 'CLEAR_RESULTS_CHANNELS_BY_SERVICE_IDS';

// DISCOVER
export const DISCOVER_GET_LIST = 'DISCOVER_GET_LIST';
export const DISCOVER_CLEAN_LIST = 'DISCOVER_CLEAN_LIST';
export const DISCOVER_GET_FILTERS = 'DISCOVER_GET_FILTERS';
export const DISCOVER_GET_SEARCH = 'DISCOVER_GET_SEARCH';
export const DISCOVER_CLEAN_SEARCH = 'DISCOVER_CLEAN_SEARCH';
export const DISCOVER_GET_COLLECTIONS = 'DISCOVER_GET_COLLECTIONS';

// APP DETAILS
export const APP_DETAILS_GET = 'APP_DETAILS_GET';
export const APP_CHANNELS_GET = 'APP_CHANNELS_GET';
export const APP_DETAILS_CLEAN = 'APP_DETAILS_CLEAN';
export const APP_GET_REVIEWS = 'APP_GET_REVIEWS';
export const APP_ADD_REVIEW = 'APP_ADD_REVIEW';
export const APP_GET_EXTERNAL_LINK = 'APP_GET_EXTERNAL_LINK';
export const APP_LIST_UPDATE_STATUS = 'APP_LIST_UPDATE_STATUS';
export const APP_LIST_REMOVE = 'APP_LIST_REMOVE';
export const APP_LIST_UPDATE_APP_STATUS = 'APP_LIST_UPDATE_APP_STATUS';
export const APP_LIST_UPDATE_APP_STATUSES = 'APP_LIST_UPDATE_APP_STATUSES';

// PLAN DETAILS
export const PLAN_DETAILS_GET = 'PLAN_DETAILS_GET';

// MOVIE DETAILS
export const CONTENT_SUBSCRIBE = 'CONTENT_SUBSCRIBE';
export const MOVIE_DETAILS_GET = 'MOVIE_DETAILS_GET';
export const MOVIE_SIMILAR_GET = 'MOVIE_SIMILAR_GET';
export const MOVIE_STREAMING_DATA_GET = 'MOVIE_STREAMING_DATA_GET';
export const MOVIE_UPDATE_MEDIA = 'MOVIE_UPDATE_MEDIA';

// SHOW DETAILS
export const SHOW_DETAILS_GET = 'SHOW_DETAILS_GET';
export const SHOW_SIMILAR_GET = 'SHOW_SIMILAR_GET';
export const SHOW_STREAMING_DATA_GET = 'SHOW_STREAMING_DATA_GET';
export const SHOW_UPDATE_MEDIA = 'SHOW_UPDATE_MEDIA';

// CONTENT
export const CONTENT_GET_MEDIA = 'CONTENT_GET_MEDIA';
export const CONTENT_CHANGE_STATUS = 'CONTENT_CHANGE_STATUS';
export const CONTENT_GET_REVIEWS = 'CONTENT_GET_REVIEWS';
export const CONTENT_ADD_REVIEW = 'CONTENT_ADD_REVIEW';

// INTERNET OPTIONS
export const INTERNET_OPTION_GET = 'INTERNET_OPTION_GET';

// INTERNET OFFERS
export const INTERNET_OFFERS_GET_DATA = 'INTERNET_OFFERS_GET_DATA';
export const INTERNET_OFFERS_GET_LIST = 'INTERNET_OFFERS_GET_LIST';
export const INTERNET_OFFERS_GET_LIST_BY_ID = 'INTERNET_OFFERS_GET_LIST_BY_ID';
export const INTERNET_OFFERS_SAVE_ANSWERS = 'INTERNET_OFFERS_SAVE_ANSWERS';
export const INTERNET_OFFERS_CLEAN = 'INTERNET_OFFERS_CLEAN';
export const INTERNET_OFFERS_SUBSCRIBE = 'INTERNET_OFFERS_SUBSCRIBE';
export const INTERNET_OFFERS_CHECK_SUBSCRIPTION = 'INTERNET_OFFERS_CHECK_SUBSCRIPTION';

// LOCATIONS
export const GET_LOCATIONS = 'GET_LOCATIONS';

// WATCH_NEXT
export const WATCH_NEXT_START_FORM = 'WATCH_NEXT_START_FORM';
export const WATCH_NEXT_SUBMIT_FORM = 'WATCH_NEXT_SUBMIT_FORM';
export const WATCH_NEXT_GET_RESULTS = 'WATCH_NEXT_GET_RESULTS';
export const WATCH_NEXT_CLEAR_RESULTS = 'WATCH_NEXT_CLEAR_RESULTS';
export const WATCH_NEXT_SKIP_CONTENT = 'WATCH_NEXT_SKIP_CONTENT';
export const WATCH_NEXT_REFRESH_RECOMMENDS = 'WATCH_NEXT_REFRESH_RECOMMENDS';
export const WATCH_NEXT_UPDATE_REC_CONTENT = 'WATCH_NEXT_UPDATE_REC_CONTENT';
export const WATCH_NEXT_GET_MORE_SHOWS = 'WATCH_NEXT_GET_MORE_SHOWS';
export const WATCH_NEXT_SUBMIT_VOTE = 'WATCH_NEXT_SUBMIT_VOTE';
export const WATCH_NEXT_ADD_EMAIL = 'WATCH_NEXT_ADD_EMAIL';
export const WATCH_NEXT_UPDATE_VOTER_DATA = 'WATCH_NEXT_UPDATE_VOTER_DATA';
export const WATCH_NEXT_AUTOCOMPLETE = 'WATCH_NEXT_AUTOCOMPLETE';
export const WATCH_NEXT_AUTOCOMPLETE_CLEAR = 'WATCH_NEXT_AUTOCOMPLETE_CLEAR';
export const WATCH_NEXT_ADD_SUGGESTION = 'WATCH_NEXT_ADD_SUGGESTION';
export const WATCH_NEXT_ADD_TO_VOTELIST = 'WATCH_NEXT_ADD_TO_VOTELIST';
export const WATCH_NEXT_REMOVE_FROM_VOTELIST = 'WATCH_NEXT_REMOVE_FROM_VOTELIST';
export const WATCH_NEXT_SAVE_VOTELIST = 'WATCH_NEXT_SAVE_VOTELIST';
export const WATCH_NEXT_DELETE_VOTELIST = 'WATCH_NEXT_DELETE_VOTELIST';

// WATCH LIST
export const WATCH_LIST_ADD_TO = 'WATCH_LIST_ADD_TO';
export const WATCH_LIST_ADD_TO_DEFAULT_LIST = 'WATCH_LIST_ADD_TO_DEFAULT_LIST';
export const WATCH_LIST_REMOVE_FROM = 'WATCH_LIST_REMOVE_FROM';
export const WATCH_LIST_CLEAN = 'WATCH_LIST_CLEAN';
export const WATCH_LIST_GET_COLLECTIONS = 'WATCH_LIST_GET_COLLECTIONS';
export const WATCH_LIST_GET = 'WATCH_LIST_GET';
export const WATCH_LIST_GET_CONTENT = 'WATCH_LIST_GET_CONTENT';
export const WATCH_LIST_COPY_CONTENT = 'WATCH_LIST_COPY_CONTENT';
export const WATCH_LIST_GET_LIST = 'WATCH_LIST_GET_LIST';
export const WATCH_LIST_CLEAN_LIST = 'WATCH_LIST_CLEAN_LIST';
export const WATCH_LIST_UPDATE = 'WATCH_LIST_UPDATE';
export const WATCH_LIST_DELETE = 'WATCH_LIST_DELETE';
export const WATCH_LIST_CREATE = 'WATCH_LIST_CREATE';
export const WATCH_LIST_ADD_CONTENT_DIALOG_MANAGE_STATE =
  'WATCH_LIST_ADD_CONTENT_DIALOG_MANAGE_STATE';
export const WATCH_LIST_GET_CONTENT_ADD_NEW_LIST = 'WATCH_LIST_GET_CONTENT_ADD_NEW_LIST';
export const WATCH_LIST_ADD_CONTENTS_TO_WATCHLISTS = 'WATCH_LIST_ADD_CONTENTS_TO_WATCHLISTS';

// SHOWS AND MOVIES
export const SHOWS_AND_MOVIES_GET = 'SHOWS_AND_MOVIES_GET';
export const SHOWS_AND_MOVIES_CLEAN = 'SHOWS_AND_MOVIES_CLEAN';
export const SHOWS_AND_MOVIES_GET_FILTERS = 'SHOWS_AND_MOVIES_GET_FILTERS';
export const SHOWS_AND_MOVIES_GET_SEARCH = 'SHOWS_AND_MOVIES_GET_SEARCH';
export const SHOWS_AND_MOVIES_CLEAN_SEARCH = 'SHOWS_AND_MOVIES_CLEAN_SEARCH';
export const SHOWS_AND_MOVIES_GET_COLLECTIONS = 'SHOWS_AND_MOVIES_GET_COLLECTIONS';

// ONBOARDING
export const ONBOARDING_GET_LIST = 'ONBOARDING_GET_LIST';
export const ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE';
export const ONBOARDING_SET_STATE = 'ONBOARDING_SET_STATE';
export const ONBOARDING_UPDATE_SELECTED_APPS = 'ONBOARDING_UPDATE_SELECTED_APPS';

// SUBSCRIPTION
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const SUBSCRIPTION_DETAILS = `${SUBSCRIPTION}_DETAILS`;
export const SUBSCRIPTION_DETAILS_GET = `${SUBSCRIPTION_DETAILS}_GET`;
export const SUBSCRIPTION_DETAILS_CLEAR = `${SUBSCRIPTION_DETAILS}_CLEAR`;
export const SUBSCRIPTION_DICTIONARY_GET = `${SUBSCRIPTION}_DICTIONARY_GET`;
export const SUBSCRIPTION_DICTIONARY_UPDATE = `${SUBSCRIPTION}_DICTIONARY_UPDATE`;
export const SUBSCRIPTION_STATUS_GET = `${SUBSCRIPTION}_STATUS_GET`;
export const SUBSCRIPTION_PAYMENT = `${SUBSCRIPTION}_PAYMENT`;
export const SUBSCRIPTION_REGISTRATION = `${SUBSCRIPTION}_REGISTRATION`;
export const SUBSCRIPTION_REGISTER_SERVICE = `${SUBSCRIPTION}_REGISTER_SERVICE`;
export const SUBSCRIPTION_CLEAR_REGISTER_ERRORS = `${SUBSCRIPTION}_CLEAR_REGISTER_ERRORS`;
export const SUBSCRIPTION_CANCEL = `${SUBSCRIPTION}_CANCEL`;
export const SUBSCRIPTION_RENEW = `${SUBSCRIPTION}_RENEW`;
export const SUBSCRIPTION_PREVIEW = `${SUBSCRIPTION}_PREVIEW`;
export const SUBSCRIPTION_TRANSACTIONS = `${SUBSCRIPTION}_TRANSACTIONS`;
export const SUBSCRIPTION_TRANSACTIONS_GET = `${SUBSCRIPTION_TRANSACTIONS}_GET`;
export const SUBSCRIPTION_TRANSACTIONS_CLEAR = `${SUBSCRIPTION_TRANSACTIONS}_CLEAR`;
export const SUBSCRIPTION_AUTH_DIALOG_MANAGE_STATE = 'SUBSCRIPTION_AUTH_DIALOG_MANAGE_STATE';
export const SUBSCRIPTION_PASSWORD_CHANGE = `${SUBSCRIPTION}_PASSWORD_CHANGE`;

// HOME
export const HOME_AUTH_GET_CONTENT = 'HOME_AUTH_GET_CONTENT';
export const HOME_NON_AUTH_GET_CONTENT = 'HOME_NON_AUTH_GET_CONTENT';
export const HOME_PARTNER_AUTH_GET_CONTENT = 'HOME_PARTNER_AUTH_GET_CONTENT';
export const HOME_AUTH_CLEAN_CONTENT = 'HOME_AUTH_CLEAN_CONTENT';
export const HOME_GET_SECTION_CONTENT = 'HOME_GET_SECTION_CONTENT';

// SWIMLANES
export const SWIMLANES_GET = 'SWIMLANES_GET';
export const SWIMLANES_GET_CONTENT = 'SWIMLANES_GET_CONTENT';
export const SWIMLANES_GET_POPULAR_CONTENT = 'SWIMLANES_GET_POPULAR_CONTENT';
export const SWIMLANES_CLEAR = 'SWIMLANES_CLEAR';

// PROMOMOTION
export const PROMOMOTION_GET_DETAILS = 'PROMOMOTION_GET_DETAILS';
export const PROMOMOTION_GET_CODE = 'PROMOMOTION_GET_CODE';
export const PROMOMOTION_CHECK_CODE = 'PROMOMOTION_CHECK_CODE';
export const PROMOMOTION_CLEAN_STATE = 'PROMOMOTION_CLEAN_STATE';
export const PROMOMOTION_CHECK_AUTH_CODE = 'PROMOMOTION_CHECK_AUTH_CODE';
export const PROMOTION_GET_SWIMLANES = 'PROMOTION_GET_SWIMLANES';

// EMMY AWARDS
export const EMMY_AWARDS_GET_DETAILS = 'EMMY_AWARDS_GET_DETAILS';

// SEARCH
export const SEARCH_GET_DATA = 'SEARCH_GET_DATA';
export const SEARCH_CLEAN_DATA = 'SEARCH_CLEAN_DATA';

// SHARE
export const SHARE_GET_INFO = 'SHARE_GET_INFO';

// _____ WP BLOG
export const WP_BLOG_GET_INSIGHTS = 'WP_BLOG_GET_INSIGHTS';
export const WP_BLOG_GET_POSTS = 'WP_BLOG_GET_POSTS';
export const WP_BLOG_CLEAR_POSTS = 'WP_BLOG_CLEAR_POSTS';
export const WP_BLOG_GET_POST = 'WP_BLOG_GET_POST';
export const WP_BLOG_GET_FEATURED_POST = 'WP_BLOG_GET_FEATURED_POST';
export const WP_BLOG_GET_RELATED_POSTS = 'WP_BLOG_GET_RELATED_POSTS';
export const WP_BLOG_CLEAN_RELATED_POSTS = 'WP_BLOG_CLEAN_RELATED_POSTS';
export const WP_BLOG_GET_CATEGORIES = 'WP_BLOG_GET_CATEGORIES';
export const WP_BLOG_GET_AUTHOR = 'WP_BLOG_GET_AUTHOR';
export const WP_BLOG_GET_CATEGORY = 'WP_BLOG_GET_CATEGORY';
export const WP_BLOG_GET_PAGE = 'WP_BLOG_GET_PAGE';
export const WP_BLOG_GET_CAREERS = 'WP_BLOG_GET_CAREERS';
export const WP_BLOG_GET_NEWS = 'WP_BLOG_GET_NEWS';

// _____ IP-API
export const IP_API_GET_IP_INFO = 'IP_API_GET_IP_INFO';

// CONFIRMATION ISP
export const CONFIRMATION_ISP = 'CONFIRMATION_ISP';

// STREAMING REWARDS
export const STREAMING_REWARDS_GET = 'STREAMING_REWARDS_GET';
export const STREAMING_REWARDS_UPDATE = 'STREAMING_REWARDS_UPDATE';
export const STREAMING_REWARDS_CLEAR = 'STREAMING_REWARDS_CLEAR';
export const STREAMING_REWARDS_CHECK = 'STREAMING_REWARDS_CHECK';
export const STREAMING_REWARDS_CHECK_USER_ELIGIBILITY = 'STREAMING_REWARDS_CHECK_USER_ELIGIBILITY';
export const STREAMING_REWARDS_CLAIM = 'STREAMING_REWARDS_CLAIM';
export const STREAMING_REWARDS_GET_CLAIM_INST = 'STREAMING_REWARDS_GET_CLAIM_INST';
export const STREAMING_REWARDS_SET_PLAY_ANIMATION = 'STREAMING_REWARDS_SET_PLAY_ANIMATION';
export const STREAMING_REWARDS_SET_ANIMATION_CARD_POS = 'STREAMING_REWARDS_SET_ANIMATION_CARD_POS';
export const STREAMING_REWARDS_GET_IMAGE_APPS = 'STREAMING_REWARDS_GET_IMAGE_APPS';
export const STREAMING_REWARDS_GET_APP_SEARCH = 'STREAMING_REWARDS_GET_APP_SEARCH';
export const STREAMING_REWARDS_CLEAR_APP_SEARCH = 'STREAMING_REWARDS_CLEAR_APP_SEARCH';
export const STREAMING_REWARDS_COMPLETE_REDEEM = 'STREAMING_REWARDS_COMPLETE_REDEEM';
export const STREAMING_REWARDS_MANAGE_USED_TOKEN_DIALOG_STATE =
  'STREAMING_REWARDS_MANAGE_USED_TOKEN_DIALOG_STATE';
export const STREAMING_REWARDS_CLAIM_REWARD_CODE = 'STREAMING_REWARDS_CLAIM_REWARD_CODE';

// STREAMING REWARDS FUNDS
export const STREAMING_REWARDS_FUNDS_PAY = 'STREAMING_REWARDS_FUNDS_PAY';
export const STREAMING_REWARDS_FUNDS_RESET_STATE = 'STREAMING_REWARDS_FUNDS_RESET_STATE';
export const STREAMING_REWARDS_FUNDS_CANCEL_RECURRING_PAYMENT =
  'STREAMING_REWARDS_FUNDS_CANCEL_RECURRING_PAYMENT';
export const STREAMING_REWARDS_FUNDS_GET_CLIENT_SECRET =
  'STREAMING_REWARDS_FUNDS_GET_CLIENT_SECRET';
export const STREAMING_REWARDS_FUNDS_ADD_FUNDS = 'STREAMING_REWARDS_FUNDS_ADD_FUNDS';
export const STREAMING_REWARDS_FUNDS_ADD_FUNDS_BY_PM = 'STREAMING_REWARDS_FUNDS_ADD_FUNDS_BY_PM';
export const STREAMING_REWARDS_FUNDS_RETRIEVE_PAYMENT_STATUS =
  'STREAMING_REWARDS_FUNDS_RETRIEVE_PAYMENT_STATUS';
export const STREAMING_REWARDS_REISSUE_CARD = 'STREAMING_REWARDS_REISSUE_CARD';

// VIRTUAL CARD
export const VIRTUAL_CARD_GET_EXPANDED_DATA = 'VIRTUAL_CARD_GET_EXPANDED_DATA';
export const VIRTUAL_CARD_CLEAR_EXPANDED_DATA = 'VIRTUAL_CARD_CLEAR_EXPANDED_DATA';
export const VIRTUAL_CARD_GET_IFRAME_TOKEN = 'VIRTUAL_CARD_GET_IFRAME_TOKEN';

// VIRTUAL CARD BALANCE
export const VIRTUAL_CARD_BALANCE = 'VIRTUAL_CARD_BALANCE';
export const VIRTUAL_CARD_BALANCE_GET_DATA = 'VIRTUAL_CARD_BALANCE_GET_DATA';

// PAYMENT HISTORY
export const PAYMENT_HISTORY_GET = 'PAYMENT_HISTORY_GET';

// CHANNEL LINEUP
export const CHANNEL_LINEUP_DICTIONARY = 'CHANNEL_LINEUP_DICTIONARY';
export const CHANNEL_LINEUP_GET_TOP = 'CHANNEL_LINEUP_GET_TOP';
export const CHANNEL_LINEUP_SEARCH = 'CHANNEL_LINEUP_SEARCH';
export const CHANNEL_LINEUP_GET_CHANNELS = 'CHANNEL_LINEUP_GET_CHANNELS';
export const CHANNEL_LINEUP_GET_REPLACEMENTS_CHANNELS = 'CHANNEL_LINEUP_GET_REPLACEMENTS_CHANNELS';
export const CHANNEL_LINEUP_CLEAN_STATE = 'CHANNEL_LINEUP_CLEAN_STATE';

// SLING BEST PACKAGE
export const SBP_GET_PACKAGE = 'SBP_GET_PACKAGE';
export const SBP_CLEAR_RESULTS = 'SBP_CLEAR_RESULTS';

// PROMOTIONS
export const PROMOTIONS_GET_DICTIONARY = 'PROMOTIONS_GET_DICTIONARY';
export const PROMOTIONS_GET_LIST = 'PROMOTIONS_GET_LIST';
export const PROMOTIONS_GET_BLACK_FRIDAY_CONTENT = 'PROMOTIONS_GET_BLACK_FRIDAY_CONTENT';
