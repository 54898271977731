export default {
  welcome: {
    title: 'Get the Live TV\nyou love',
    subtitle:
      'Just answer a few questions, select the channels you\n' +
      'watch and we’ll show you the best streaming packages at\n' +
      'the best price for you!',
    dontKnowWhereStart:
      'Don’t know where to start? Check out our <a to="/pages/streaming-101-guide">streaming 101 guide</a> or <hashLink to="#faq">FAQs</hashLink>',
    imagealt: 'Find the best Live TV streaming for you',
  },
  acpQualification: {
    title: 'Affordable Connectivity Program (ACP) Qualification',
    step1: 'Based on <span>household</span> income',
    step2:
      'If <b>you</b> and <b>your child</b> or <b>dependent</b> participate in certain government assistance programs such as SNAP, Medicaid, WIC, or <span>other programs</span>.',
    step3: 'If <b>you</b> or <b>anyone</b> in your household already receives a Lifeline benefit.',
    button: 'Check Your Qualification',
  },
  streamingMarketplace: {
    title: 'Streaming Marketplace',
    subtitle:
      'Explore our marketplace to find new services build your personalized streaming package!',
  },
  streamingDevice: {
    title: 'Streaming Device',
    subtitle:
      'Roku connects to your TV and internet so you can stream your favorite shows and movies!',
  },
  showsMovies: {
    title: 'Shows and Movies Guide',
    subtitle: 'Search any show or movie and find where it is streaming right now.',
  },
  appSection: {
    title: 'MyBundle Streaming TV Guide App',
    subtitle:
      'One universal, up-to-the-minute streaming guide in the palm of your hands. Search any show or movie to see where to watch, synopses, ratings and trailers. Even manage your monthly streaming budget, build watchlists and share with friends.',
  },
  liveTV: {
    title: 'Watch Free Live TV',
    subtitle: 'Tune in for Hit Movies, TV Shows, News, Sports, Kids and more. All completely free!',
    features: {
      item1: 'Personalized Show & Movie Recommendations',
      item2: 'Search Where to Watch Specific Titles',
      item3: 'Manage your Streaming Expenses',
      item4: 'Create Watchlists & Track What You’re Watching',
    },
  },
  questions: {
    title: 'Need More Help?',
    question1: {
      question: 'What is Streaming TV? ',
      answer:
        'Put simply, streaming TV is entertainment transmitted over the internet instead of a cable or satellite box. With our reliable internet, you can stream your favorite TV channels, shows, movies, documentaries, and more – live or on demand. Using a streaming device or smart TV, you can watch services on your TV, or you can stream them on your computer or mobile devices. ',
    },
    question2: {
      question: 'What is MyBundle? ',
      answer:
        "We've partnered with MyBundle to help simplify your streaming TV experience because we know it can be confusing. MyBundle's suite of free tools help you eliminate all the work needed to find the streaming services that have the content you actually want. Whether it's finding a replacement for cable or satellite with the channels you need, discovering new streaming services that match your interests, or deciding what to watch next – MyBundle can help. (And save you hundreds of dollars and hours in the process!)",
    },
    question3: {
      question: 'How does Find My Bundle work?',
      answer:
        'Answer a few questions about your lifestyle and viewing preferences. Input your zip code. Select your must-have live TV channels, and Find My Bundle will present the best streaming TV service(s) for you to replace cable TV. Enter your email if you’d like a copy of your results, or go ahead and sign-up for the service(s) that best matches your needs and budget right there. ',
    },
    question4: {
      question: 'What’s a Smart TV or streaming device? Do I need one? ',
      answer:
        'If you’d like to enjoy streaming content on your TV, you’ll need a Smart TV or streaming device. A lot of newer TVs come equipped Smart TV capabilities, which allow you to connect your TV to your Wi-Fi network or modem and access streaming service apps. Alternatively, a streaming device such as a Roku, Amazon’s Firestick, Apple TV, or Google Chromecast, will turn your TV into a Smart TV. With a simple interface, streaming devices allow you to use streaming apps and watch your favorite shows and movies.',
    },
    question5: {
      question: 'Why should I switch to Streaming?',
      answer:
        'Cable TV is constantly getting more expensive and often includes more channels than you actually watch. With Streaming, you can enjoy the same content and channels you’re used to in a more affordable package. Beyond live TV, with streaming you can access low-cost or free streaming services that offer movies, shows, and documentaries that match your interests. Streaming also provides you the ability to watch your favorite content wherever and however you want.',
    },
    question6: {
      question: 'Who should I contact if I am having trouble with my streaming service?',
      answer:
        'You will need to contact your streaming service directly. Follow these links to contact: <a href="https://help.netflix.com/en">Netflix</a>, <a href="https://help.hulu.com/">Hulu</a>, <a href="https://help.disneyplus.com/">Disney+</a>, <a href="https://support.paramountplus.com/s/?language=en_US">Paramount+</a>, <a href="https://www.peacocktv.com/help">Peacock</a>, <a href="https://www.primevideo.com/help/ref=atv_ftr_hp">Prime</a>, <a href="https://help.max.com/us/Home/Index">Max</a>, <a href="https://help.max.com/us/Home/Index">Roku</a>, <a href="https://support.google.com/youtubetv/?hl=en#topic=7071745">Youtube TV</a>, and <a href="https://www.sling.com/help/en">Sling</a>.',
    },
  },
};
