import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// CONSTANTS
import { ROUTE_PARTNER_SOCIAL_LOGIN } from '@/constants/routes';

// UTILITY
import { auth } from '@/utility/firebase';
import { getLink } from '@/utility/routes';

// STORE
import { AuthActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// COMPONENTS
import FacebookColoredIcon from '@/components/Icons/FacebookColoredIcon';
import GoogleColoredIcon from '@/components/Icons/GoogleColoredIcon';

// STYLES
import { Container } from './styles';

// const env = process.env.REACT_APP_ENV;
const appPublicPath = process.env.REACT_APP_PUBLIC_PATH;

const SocialLogin = ({ submitData, onSuccess }) => {
  const [t] = useTranslation();

  const { partner, partnerSettings, isPartnerDomain } = useSelector(
    ({ partner: partnerStore }) => ({
      partner: partnerStore.data,
      partnerSettings: partnerStore.data?.settings || {},
      isPartnerDomain: partnerStore.isPartnerDomain,
    }),
  );

  const [authSocialLogin] = useActions([
    (providerId, data, callback) =>
      AuthActions.authSocialLogin.action({ providerId, data, callback }),
  ]);

  // const isLocalHost = env === 'local';
  const hasPartner = !!partner.slug;
  let useAuth = true;
  let useSocialLogin = true;

  if (hasPartner) {
    useAuth = !!partnerSettings.use_authorizing;
    useSocialLogin = !!partnerSettings.use_social_login;
  }

  const handleSocialLogin = providerId => {
    if (isPartnerDomain) {
      const { origin, pathname } = window.location;
      // const domain = isLocalHost ? 'http://localhost:3000' : appPublicPath;
      const domain = appPublicPath;
      const slPath = getLink(ROUTE_PARTNER_SOCIAL_LOGIN, { partner: partner.slug });

      window.location.href = `${domain}${slPath}?social=${providerId}&redirect_url=${origin}${pathname}`;
    } else {
      authSocialLogin(providerId, submitData, onSuccess);
    }
  };

  if (!auth || !useAuth || !useSocialLogin) return;

  return (
    <Container className="social-login">
      <Typography className="social-login__title" align="center">
        <span>{t('socialLogin.title')}</span>
      </Typography>
      <div className="social-login__controls">
        <Button variant="outlined" onClick={() => handleSocialLogin('google')}>
          <GoogleColoredIcon />
        </Button>
        <Button variant="outlined" onClick={() => handleSocialLogin('facebook')}>
          <FacebookColoredIcon />
        </Button>
        <Button variant="outlined" onClick={() => handleSocialLogin('apple')}>
          <i className="icon-apple" />
        </Button>
      </div>
    </Container>
  );
};

SocialLogin.defaultProps = {
  submitData: {},
};

SocialLogin.propTypes = {
  submitData: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default React.memo(SocialLogin);
