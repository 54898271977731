import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Tooltip, Typography, Button, useMediaQuery } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import throttle from 'lodash.throttle';

// CONSTANTS
import { ROUTE_STREAMING_REWARDS } from '@/constants/routes';
import { LOCATIONS_HEADER } from '@/constants/locations';
import {
  CardPaymentType,
  SRClaimMode,
  urlTypes,
  StreamingRewardsCardStatus,
} from '@/constants/streamingRewards';

// STORE
import { UserActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// HOC
import withVirtualCardData from '@/hoc/withVirtualCardData';

// UTILITY
import { formatBalance } from '@/utility/currency';
import getLink from '@/utility/routes';
import { checkMultipleAppsRewardType } from '@/utility/streamingRewards';

// COMPONENTS
import Link from '@/components/Link';
import VirtualCard from '@/containers/StreamingRewards/help/VirtualCard';
import ReactHtml from '@/components/ReactHtml/ReactHtml';

// STYLES
import { BalanceButton, BalanceContent, BalanceTooltipPopper } from './styles';

const VirtualCreditCard = withVirtualCardData(VirtualCard);

function BalanceTooltip({ className = '' }) {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const downXs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const [
    user,
    card,
    rewardData,
    cardBalance,
    partnerData,
    urlType,
  ] = useSelector(({ auth, user: userStore, partner }) => [
    auth.user,
    userStore.streamingRewardWidget?.card || auth.user?.card,
    userStore.streamingRewardWidget?.streaming_reward || auth.user?.eligible_streaming_reward,
    userStore.streamingRewardWidget?.card.balance || auth.user?.card?.balance || 0,
    partner.data || {},
    auth.user?.eligible_streaming_reward?.url_type || '',
  ]);
  const [getSRWidgetData] = useActions([UserActions.userGetSRWidgetData.action]);
  const getSRWidgetDataThrottled = useRef(throttle(getSRWidgetData, 10000)).current;
  const isPartner = !!Object.keys(partnerData).length;
  const textOverwrites = partnerData?.text_overwrites || {};
  const isSC = urlType === urlTypes.STREAMING_CHOICE;
  const isBestProvider = card?.payment_type === CardPaymentType.BEST;
  const isConciergeClaimMode = rewardData?.claim_mode === SRClaimMode.CONCIERGE;
  const showAsLink = downXs || isConciergeClaimMode;
  const isFintechProvider = card?.payment_type === CardPaymentType.FINTECH;
  const isPendingReissueS = card?.status === StreamingRewardsCardStatus.PENDING_REISSUE;
  const fintechCardDisclaimer =
    textOverwrites?.fintech_card_disclaimer ?? t('streamingRewards.card.fintechCardDisclaimer');
  const showFintechDisclaimer = isFintechProvider && !!fintechCardDisclaimer && !isPendingReissueS;
  const bestCardDisclaimer =
    textOverwrites?.best_card_disclaimer ?? t('streamingRewards.card.bestCardDisclaimer');
  const showBestDisclaimer = isBestProvider && !!bestCardDisclaimer && !isPendingReissueS;
  const zipcode = user?.zipcode;

  const needShowTooltip =
    checkMultipleAppsRewardType(rewardData) &&
    ((isPartner && partnerData.slug === rewardData.partner) ||
      (!isPartner && rewardData.partner === null));

  if (!needShowTooltip) {
    return null;
  }

  const balance = formatBalance(cardBalance, '$');
  const srLink = getLink(ROUTE_STREAMING_REWARDS, { slug: rewardData.slug });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    getSRWidgetDataThrottled();

    setOpen(true);
  };

  if (showAsLink) {
    return (
      <BalanceButton
        color="secondary"
        className={clsx('balance-button', className)}
        location={LOCATIONS_HEADER}
        component={Link}
        to={srLink}
      >
        <CreditCardIcon />
      </BalanceButton>
    );
  }

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="bottom-end"
      interactive
      leaveTouchDelay={10000}
      arrow={false}
      PopperComponent={BalanceTooltipPopper}
      title={
        <BalanceContent className="content">
          <Typography variant="h6" className="content__title">
            {t(`${isSC ? 'streamingChoice' : 'streamingRewards'}.cardBalanceTooltip.title`)}{' '}
            <span className="balance">{balance}</span>
          </Typography>
          <Typography className="p5 content__subtitle">
            <Trans i18nKey="streamingRewards.cardBalanceTooltip.desc" values={{ balance }} />
          </Typography>
          {isBestProvider && !!zipcode && (
            <Typography className="p5 m-b-5">
              {t('common.billingZipcode')}: {zipcode}
            </Typography>
          )}
          <VirtualCreditCard size="small" wide={false} />
          {showFintechDisclaimer && (
            <ReactHtml className="markdown p5 m-t-10" align="center" component="p">
              {fintechCardDisclaimer}
            </ReactHtml>
          )}
          {showBestDisclaimer && (
            <ReactHtml className="markdown p5 m-t-10" align="center" component="p">
              {bestCardDisclaimer}
            </ReactHtml>
          )}
          <Button
            variant="contained"
            color="primary"
            className="content__button"
            size="medium"
            location={LOCATIONS_HEADER}
            component={Link}
            to={srLink}
          >
            {t(`${isSC ? 'streamingChoice' : 'streamingRewards'}.cardBalanceTooltip.btnText`)}
          </Button>
        </BalanceContent>
      }
    >
      <BalanceButton
        color="secondary"
        className={clsx('balance-button', className)}
        onClick={() => setOpen(!open)}
      >
        <CreditCardIcon /> <span className="balance">{balance}</span>
      </BalanceButton>
    </Tooltip>
  );
}

BalanceTooltip.propTypes = {
  className: PropTypes.string,
};

export default React.memo(BalanceTooltip);
