import * as Yup from 'yup';

// CONSTANTS
import * as validationConst from '@/constants/validation';

export default t => {
  return {
    // first_name: Yup.string().required(t('validation.firstNameRequired')),
    // last_name: Yup.string().required(t('validation.lastNameRequired')),
    zipcode: Yup.string()
      .min(
        validationConst.MIN_ZIP_CODE_LENGTH,
        t('validation.minZipCodeLength', { length: validationConst.MIN_ZIP_CODE_LENGTH }),
      )
      .max(
        validationConst.MAX_ZIP_CODE_LENGTH,
        t('validation.maxZipCodeLength', { length: validationConst.MAX_ZIP_CODE_LENGTH }),
      )
      .required(),
  };
};
