import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';

// UTILITY
import { breakpoints } from '@/utility/breakpoints';

// CONSTANTS
import { HINT_OF_RED } from '@/constants/colors';

export const CloseIcon = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;

  font-size: 1.5rem;
`;

export const DialogStyled = styled(Dialog)`
  .MuiDialog-paper {
    overflow-y: visible;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
  }

  .MuiDialogContent-root {
    padding-bottom: 24px;

    display: flex;
    flex-direction: column;

    &:first-child {
      padding-top: 60px;
    }
  }

  .dialog-cta {
    &__main-content {
      display: flex;
      flex-direction: column;
    }

    &__title-wrapper,
    &__image-wrapper {
      display: flex;
      align-items: center;
    }

    &__title {
      width: 100%;
    }

    &__confirm-btn + .dialog-cta__cancel-btn {
      margin-top: 15px;
    }

    &__icon {
      height: 1em;
      width: 1em;
      font-size: 48px;

      margin-left: 8px;
    }

    &__controls {
      text-align: center;
    }
  }

  &.dialog-cta {
    &--inside-container {
      z-index: 1000 !important;
      top: ${p => p.theme.headerHeight || 0}px !important;
    }

    &--info {
      .MuiDialogContent-root {
        padding: 0;
      }

      .dialog-cta {
        &__image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 334px;
          padding: 24px;
          background-color: ${HINT_OF_RED};
          margin-bottom: 0;
          border-radius: 20px 20px 0 0;

          ${breakpoints.down('xs')} {
            height: 254px;
            padding-right: 16px;
            padding-left: 16px;
          }

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        &__content {
          min-height: 256px;
          padding: 24px 24px 38px;
          text-align: center;

          ${breakpoints.down('xs')} {
            min-height: auto;
            padding-right: 16px;
            padding-left: 16px;
            padding-bottom: 24px;
          }
        }
      }
    }
  }
`;

export default DialogStyled;
