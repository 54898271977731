export default {
  title: 'Streaming Choice Card',
  description:
    'Use your Streaming Choice Card from MyBundle to subscribe and manage to your favorite streaming TV and video services',
  balance: {
    title: 'Streaming Choice Card Balance',
  },
  cardBalanceTooltip: {
    title: 'Available Streaming Choice Card Balance',
    btnText: 'Go to My Streaming Choice',
  },
  howItWorks: {
    useReward: 'How to use your Streaming Choice Card?',
  },
  not_enough_credits: {
    subtitle:
      'Looks like your Streaming Choice Card doesn’t have enough credits for this transaction. Please link your personal credit/debit card or bank account to add more credits to proceed.',
  },
  add_funds: {
    title: 'Add credits to my Streaming Choice Card',
  },
  redirect: {
    info: {
      title: '3. Use your streaming choice card to subscribe to the streaming service',
    },
  },
  instruction: {
    title: '{{name}} Signup Instructions with your\nStreaming Choice Card',
    description:
      'Please read these instructions carefully to ensure your Streaming Choice Card is used rather than\nyour personal credit card.',
  },
  tourGuide: {
    viewCard: 'View Your Streaming Choice Card',
  },
};
