export default {
  title: 'Profile Details',
  subscription: {
    email: 'Email: {{value}}',
    status: 'Status: {{value}}',
    start_date: 'Subscription start date: {{value}}',
    end_date: 'Subscription end date: {{value}}',
    cancel_date: 'Subscription cancel date: {{value}}',
    subscription_status: 'Subscription status: {{value}}',
    subscription_ended: 'Subscription ended: {{value}}',
    max_requests_error: 'Maximum requests exceeded. Try later',
    concierge: {
      activating_message:
        'Our team is working on activating your {{app_name}} account. Please look out for an confirmation email, this may take up to 12 hours. Contact help@mybundle.tv if you have any questions.',
      cancel_subscription:
        'This streaming service was registered by Mybundle concierge. To cancel the subscription, please contact our support team help@mybundle.tv',
    },
  },
  creditCard: {
    title: 'Credit Card Info',
    modalTitle: 'New Card Setup',
    confirmationDesc:
      'All subscriptions are linked to this card, are you sure you want to change current card and link all subscriptions to new one?',
    setupDesc: 'Please add a new credit card',
  },
};
