import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// STYLES
import { DialogStyled, CloseIcon } from './styles';

const DialogCTA = ({
  open,
  onClose,
  title,
  titleProps,
  icon,
  image,
  subtitle,
  subtitleProps,
  onConfirmClick,
  buttonConfirmTitle,
  buttonConfirmProps,
  hideConfirmButton,
  onCancelClick,
  buttonCancelTitle,
  buttonCancelProps,
  hideCancelButton,
  cancelClickWithoutClose,
  hideCloseIcon,
  children,
  bottomContent,
  insideContainer,
  className,
  onEnter,
  ...props
}) => {
  function handleCancelClick() {
    if (!cancelClickWithoutClose) {
      onClose();
    }

    onCancelClick();
  }

  function addBodyClass(state = false) {
    if (props.container && !props.disableScrollLock && state) {
      window.document.body.classList.add('overflow-hidden');

      return;
    }

    window.document.body.classList.remove('overflow-hidden');
  }

  function handleOnEnter() {
    addBodyClass(true);

    onEnter();
  }

  return (
    <DialogStyled
      className={clsx('dialog-cta', insideContainer && 'dialog-cta--inside-container', className)}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={open}
      TransitionProps={{
        onEnter: handleOnEnter,
        onExit: () => addBodyClass(false),
      }}
      {...props}
    >
      <DialogContent className="dialog-cta__content">
        {!hideCloseIcon && (
          <CloseIcon
            className="dialog-cta__close-btn"
            size="medium"
            aria-label="close"
            onClick={onClose}
          >
            <i className="icon-close" />
          </CloseIcon>
        )}
        <div className="dialog-cta__content-wrap">
          <div className="dialog-cta__main-content">
            {image && <div className="dialog-cta__image-wrapper m-b-15">{image}</div>}
            <div className="dialog-cta__content">
              {!!title && (
                <div className="dialog-cta__title-wrapper">
                  <Typography
                    variant="h4"
                    align="center"
                    {...titleProps}
                    className={clsx('dialog-cta__title m-b-15', titleProps.className)}
                  >
                    {title}
                  </Typography>
                  {icon && <div className="dialog-cta__icon">{icon}</div>}
                </div>
              )}
              {!!subtitle && (
                <Typography
                  align="center"
                  {...subtitleProps}
                  className={clsx('dialog-cta__subtitle m-b-15', subtitleProps.className)}
                >
                  {subtitle}
                </Typography>
              )}
              {children}
              <div className="dialog-cta__controls">
                {!hideConfirmButton && (
                  <Button
                    fullWidth
                    color="primary"
                    onClick={onConfirmClick}
                    {...buttonConfirmProps}
                    className={clsx(
                      'sizeExtraLarge dialog-cta__confirm-btn',
                      buttonConfirmProps.className,
                    )}
                  >
                    {buttonConfirmTitle}
                  </Button>
                )}
                {!hideCancelButton && (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleCancelClick}
                    fullWidth
                    {...buttonCancelProps}
                    className={clsx(
                      'sizeExtraLarge dialog-cta__cancel-btn',
                      buttonCancelProps.className,
                    )}
                  >
                    {buttonCancelTitle}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {bottomContent}
        </div>
      </DialogContent>
    </DialogStyled>
  );
};

DialogCTA.defaultProps = {
  hideConfirmButton: false,
  hideCancelButton: false,
  cancelClickWithoutClose: false,
  hideCloseIcon: false,
  buttonConfirmProps: {},
  buttonCancelProps: {},
  title: '',
  titleProps: {},
  subtitleProps: {},
  onClose: () => {},
  onConfirmClick: () => {},
  onCancelClick: () => {},
  insideContainer: false,
  className: '',
  disableScrollLock: false,
  onEnter: () => {},
};

DialogCTA.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  title: PropTypes.any,
  titleProps: PropTypes.object,
  subtitle: PropTypes.any,
  subtitleProps: PropTypes.object,
  icon: PropTypes.any,
  image: PropTypes.any,
  buttonConfirmTitle: PropTypes.string,
  buttonConfirmProps: PropTypes.object,
  hideConfirmButton: PropTypes.bool,
  buttonCancelTitle: PropTypes.string,
  buttonCancelProps: PropTypes.object,
  hideCancelButton: PropTypes.bool,
  cancelClickWithoutClose: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  bottomContent: PropTypes.node,
  insideContainer: PropTypes.bool,
  className: PropTypes.string,
  container: PropTypes.any,
  disableScrollLock: PropTypes.bool,
  onEnter: PropTypes.func,
};

export default React.memo(DialogCTA);
