import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import PlaceIcon from '@material-ui/icons/Place';
import InfoIcon from '@material-ui/icons/Info';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// CONSTANTS
import {
  CardPaymentType,
  SRClaimMode,
  StreamingRewardsCardStatus,
} from '@/constants/streamingRewards';
import { ROUTE_WP_PAGE } from '@/constants/routes';

// UTILITY
import { getBillingAddress } from '@/utility/common';
import getLink from '@/utility/routes';

// HOOKS
import { useVirtualCard } from '@/hooks/useVirtualCardData';
import useActions from '@/hooks/useActions';

// STORE
import { StreamingRewardsActions } from '@/store/actions';

// COMPONENTS
import Loader from '@/components/Loader';
import RouterLink from '@/components/Link';

// HELP
import FieldWrap from '../FieldWrap';
import FintechCardContent from './help/FintechCardContent';

// ASSETS
import { ReactComponent as MLogoSvg } from '@/assets/images/logo-mybundle-white.svg';
import { ReactComponent as VirtualCardIconSvg } from '@/assets/images/cc-icon.svg';

// STYLES
import { Container } from './styles';

const VirtualCard = ({ wide, size, withoutAnimation, withoutCopy, showReactivateWarn }) => {
  const [t] = useTranslation();
  const { card, playAnimation, reissueCardLoading, partnerData, rewardData } = useSelector(
    ({ auth, streamingRewards, user, partner }) => ({
      card: auth.user?.card,
      playAnimation: !!streamingRewards.playAnimation,
      reissueCardLoading: !!streamingRewards.loadings.reissueCard,
      partnerData: partner.data || {},
      rewardData:
        user.streamingRewardWidget?.streaming_reward || auth.user?.eligible_streaming_reward,
    }),
  );
  const [reissueSRCard] = useActions([
    (slug, params) => StreamingRewardsActions.reissueSRCard.action({ slug, params }),
  ]);
  const downXs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const isSmallSize = downXs || size === 'small';
  const { cardNumber, cardCVC, expDate, handleInputFieldClick } = useVirtualCard();
  const hasCard = Boolean(card && card.last4);
  const isNotUsedCard = hasCard && card?.used === false;
  const isBestProvider = card?.payment_type === CardPaymentType.BEST;
  const isFintechProvider = card?.payment_type === CardPaymentType.FINTECH;
  const isIframedCard = (isBestProvider && !!card?.payment_gateway_id) || isFintechProvider;
  const isCompletedFlow = rewardData.redeem_flow_completed;
  const isPendingReissueS = card?.status === StreamingRewardsCardStatus.PENDING_REISSUE;
  const isClaimModeRestricted = rewardData?.claim_mode === SRClaimMode.RESTRICTED;
  const isRestrictedWarning = isPendingReissueS && isClaimModeRestricted && isNotUsedCard;
  const showWarning = (showReactivateWarn && isPendingReissueS) || isRestrictedWarning;

  const handleInputClick = name => {
    if (withoutCopy) return;

    handleInputFieldClick(name);
  };

  const handleReissueCardClick = () => {
    reissueSRCard(rewardData.slug, {
      slug: rewardData.slug,
      partner: partnerData.id || 0,
    });
  };

  const allowAnimation = playAnimation && !withoutAnimation && !downXs && !isIframedCard;
  const isWideMode = wide === null ? allowAnimation : wide;

  const getWarningContent = () => {
    if (!showWarning) return null;

    let content = null;

    if (isPendingReissueS) {
      content = (
        <Button
          color="primary"
          size={isSmallSize ? 'medium' : 'large'}
          onClick={handleReissueCardClick}
        >
          {t('buttons.clickSeeCard')}
        </Button>
      );

      if (isCompletedFlow) {
        content = (
          <>
            <InfoIcon className="warning-alert__icon" />
            <div>
              <Typography className="p5 text-medium text-color--white m-b-15">
                <Trans
                  i18nKey="streamingRewards.card.completedFlowNewCard"
                  components={{
                    a: (
                      <Link
                        to={getLink(ROUTE_WP_PAGE, { slug: 'streaming-credits-update' })}
                        component={RouterLink}
                        color="inherit"
                        underline="always"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </Typography>
              <Button color="primary" size="large" onClick={handleReissueCardClick}>
                {t('buttons.clickActivateNewCard')}
              </Button>
            </div>
          </>
        );
      }

      if (isCompletedFlow && isSmallSize) {
        content = (
          <>
            <InfoIcon className="warning-alert__icon" />
            <div>
              <Button
                className="m-b-10"
                color="primary"
                size="small"
                onClick={handleReissueCardClick}
              >
                {t('buttons.clickActivateNewCard')}
              </Button>
              <Typography className="p5 text-medium text-color--white">
                <Trans
                  i18nKey="streamingRewards.card.completedFlowNewCardSmallCard"
                  components={{
                    a: (
                      <Link
                        to={getLink(ROUTE_WP_PAGE, { slug: 'streaming-credits-update' })}
                        component={RouterLink}
                        color="inherit"
                        underline="always"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </Typography>
            </div>
          </>
        );
      }

      if (isCompletedFlow && isWideMode) {
        content = (
          <>
            <InfoIcon className="warning-alert__icon" />
            <div className="warning-alert__info">
              <Typography className="p5 text-medium text-color--white m-b-10">
                <Trans
                  i18nKey="streamingRewards.card.completedFlowNewCardSmallCard"
                  components={{
                    a: (
                      <Link
                        to={getLink(ROUTE_WP_PAGE, { slug: 'streaming-credits-update' })}
                        component={RouterLink}
                        color="inherit"
                        underline="always"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </Typography>
              <Button color="primary" size="small" onClick={handleReissueCardClick}>
                {t('buttons.clickActivateNewCard')}
              </Button>
            </div>
          </>
        );
      }
    }

    if (isRestrictedWarning) {
      content = (
        <>
          <InfoIcon className="warning-alert__icon" />
          <div>
            <Typography className="p5 text-medium text-color--white m-b-15">
              {t('streamingRewards.card.restrictedModeNotUsedCard')}
            </Typography>
          </div>
        </>
      );
    }

    return (
      <div className="virtual-card__warning warning-alert">
        {content}
        <Loader active={reissueCardLoading} />
      </div>
    );
  };

  const getCardContent = () => {
    if (isBestProvider && !!card?.payment_gateway_id) {
      return (
        <iframe
          title="vc"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          src={card?.payment_gateway_id}
        />
      );
    }

    if (isFintechProvider) {
      return <FintechCardContent />;
    }

    const inputAdornment = !withoutCopy && (
      <InputAdornment position="end">
        <IconButton>
          <FilterNoneIcon />
        </IconButton>
      </InputAdornment>
    );

    return (
      <>
        <div className="virtual-card__title">{!isWideMode && <MLogoSvg />}</div>
        <div className="virtual-card__mb-icon">
          <i className="icon-mb-icon-2" />
        </div>
        <Grid className="virtual-card__content" spacing={isWideMode ? 2 : 1} container>
          <Grid item xs={8} sm={isSmallSize ? 8 : 7}>
            <FieldWrap onClick={() => handleInputClick('number')} withoutCopy={withoutCopy}>
              <TextField
                disabled
                variant="standard"
                label={t('inputs.creditCardNum')}
                value={cardNumber}
                name="number"
                type="text"
                onClick={() => handleInputClick('number')}
                InputProps={{
                  endAdornment: inputAdornment,
                }}
              />
            </FieldWrap>
          </Grid>
          <Grid item xs={4} sm={isSmallSize ? 4 : 3}>
            <FieldWrap onClick={() => handleInputClick('expDate')} withoutCopy={withoutCopy}>
              <TextField
                disabled
                variant="standard"
                label={t('inputs.expDate_short')}
                value={expDate}
                name="exp_date"
                type="text"
                onClick={() => handleInputClick('expDate')}
                InputProps={{
                  endAdornment: inputAdornment,
                }}
              />
            </FieldWrap>
          </Grid>
          <Grid item xs={3} sm={isSmallSize ? 3 : 2}>
            <FieldWrap onClick={() => handleInputClick('cvc')} withoutCopy={withoutCopy}>
              <TextField
                disabled
                variant="standard"
                label={t('inputs.cvc')}
                value={cardCVC}
                name="cvc"
                type="text"
                onClick={() => handleInputClick('cvc')}
                InputProps={{
                  endAdornment: inputAdornment,
                }}
              />
            </FieldWrap>
          </Grid>
          <Grid className="virtual-card__billing" item xs={9} sm={isSmallSize ? 9 : 12}>
            <Grid alignItems="center" container spacing={1} className="billing">
              {!!card?.billing && (
                <Grid item xs={12}>
                  <Typography className="billing__label">
                    {t('streamingRewards.card.billing')}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={1} wrap="nowrap" alignItems="center">
                  {!!card?.billing && (
                    <>
                      <Grid className="billing__icon-container" item xs="auto">
                        <Box display="flex">
                          <PlaceIcon className="billing__place-icon" />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <FieldWrap
                          onClick={() => handleInputClick('billingAddress')}
                          withoutCopy={withoutCopy}
                        >
                          <Box
                            display="flex"
                            alignItems="flex-end"
                            onClick={() => handleInputClick('billingAddress')}
                          >
                            <Typography className="billing__text">
                              {getBillingAddress(card.billing)}
                            </Typography>
                            {!withoutCopy && (
                              <IconButton className="billing__copy-icon">
                                <FilterNoneIcon />
                              </IconButton>
                            )}
                          </Box>
                        </FieldWrap>
                      </Grid>
                    </>
                  )}
                  {!card?.billing && <Grid item xs={12} />}
                  <Grid className="billing__icon-wrap" item xs="auto">
                    <Box display="flex">
                      <VirtualCardIconSvg className="billing__icon" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const warningContent = showWarning && getWarningContent();
  const cardContent = getCardContent();

  return (
    <Container
      className={clsx('card-container', {
        'card-container--wide': isWideMode,
        'card-container--withoutCopy': withoutCopy,
        'card-container--withWarning': showWarning,
      })}
    >
      <div
        className={clsx('virtual-card', {
          'virtual-card--small': isSmallSize,
          [`virtual-card--iframe virtual-card--${card?.payment_type}`]: isIframedCard,
        })}
      >
        {warningContent}
        {cardContent}
      </div>
    </Container>
  );
};

VirtualCard.defaultProps = {
  wide: null,
  size: 'default',
  withoutAnimation: false,
  withoutCopy: true,
  showReactivateWarn: true,
};

VirtualCard.propTypes = {
  wide: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'default'])]),
  withoutAnimation: PropTypes.bool,
  withoutCopy: PropTypes.bool,
  showReactivateWarn: PropTypes.bool,
};

export default React.memo(VirtualCard);
