import styled from 'styled-components';

// CONSTANTS
import { MAIN_BLACK } from '@/constants/colors';

export const Container = styled.div`
  margin: 25px 0;

  .social-login {
    &__title {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 400;
      margin-bottom: 16px;

      span {
        position: relative;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          width: 50px;
          height: 1px;
          background-color: ${MAIN_BLACK};
        }

        &:before {
          right: 100%;
          margin-right: 17px;
        }

        &:after {
          left: 100%;
          margin-left: 17px;
        }
      }
    }

    &__controls {
      display: flex;
      justify-content: space-between;

      svg {
        font-size: 1.6rem;
      }

      button {
        color: ${MAIN_BLACK};
        border-radius: 3px;
        border-width: 1px;
        transition: 0.4s ease-out;
        width: 100%;
        max-width: 106px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &:hover {
          background-color: transparent;
          border-color: ${MAIN_BLACK};
        }
      }
    }
  }
`;

export default Container;
