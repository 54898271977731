import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';

// CONSTANTS
import { SWIMLANES_CONTENT_ID, SWIMLANES, SWIMLANES_GET_POPULAR_CONTENT } from '@/constants/api';

// UTILITY
import { getLink, getSerializedParams } from '@/utility/routes';
import { getPartnerSlug, showErrorNotification } from '@/utility/saga';

// STORE
import { SwimlanesActions } from '@/store/actions';
import { AuthSelectors, AppSelectors } from '@/store/selectors';

const { swimlanesGet, swimlanesGetContent, swimlanesGetPopularContent } = SwimlanesActions;

export function* swimlanesGetContentSaga(action) {
  const {
    params: { id, section, page, ignoreCache = false, ...params },
  } = action.payload;

  yield put(swimlanesGetContent.start({ id, section, page }));

  const url = getLink(SWIMLANES_CONTENT_ID, { id });
  const ytpc = yield select(AppSelectors.getYtpc);
  const serializedParams = getSerializedParams({
    ...params,
    page,
    ytpc,
  });

  try {
    const { data: respData } = yield axios.get(url, {
      ...serializedParams,
      cache: { ignoreCache },
    });

    const data = Array.isArray(respData) && respData.length ? respData[0] : respData;

    yield put(swimlanesGetContent.success({ data, id, section, page }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(swimlanesGetContent.fail({ id, section, page }));
  }
}

export function* swimlanesGetSaga(action) {
  yield put(swimlanesGet.start());

  const { page, section, tab, app_ids, ...params } = action?.payload?.params || {};

  const unique_id = yield select(AuthSelectors.getUniqueId);
  const partner = yield call(getPartnerSlug);

  const is_partner = Number(!!partner);
  const updatesParams = {
    ...params,
    page,
    section,
    tab,
    unique_id,
    partner,
    is_partner,
    app_ids,
  };
  const serializedParams = getSerializedParams(updatesParams);

  try {
    const { data: respData } = yield axios.get(SWIMLANES, serializedParams);

    const swimlaneIds = respData.map(item =>
      typeof item === 'number' ? item : item.swimlane_list_id,
    );

    yield put(swimlanesGet.success({ data: swimlaneIds, section, page }));

    for (const item of respData) {
      if (typeof item === 'object') {
        yield put(
          swimlanesGetContent.success({ data: item, id: item.swimlane_list_id, section, page }),
        );
      } else {
        yield put(
          swimlanesGetContent.init({
            params: {
              ...params,
              id: item,
              section,
              page,
              partner,
              is_partner,
              app_ids,
              unique_id,
            },
          }),
        );
      }
    }
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(swimlanesGet.fail());
  }
}

export function* swimlanesGetPopularContentSaga(action) {
  const { params = {} } = action.payload;

  yield put(swimlanesGetPopularContent.start());

  const unique_id = yield select(AuthSelectors.getUniqueId);
  const ytpc = yield select(AppSelectors.getYtpc);
  const partner = yield call(getPartnerSlug);

  const is_partner = Number(!!partner);
  const updatesParams = {
    ...params,
    unique_id,
    partner,
    is_partner,
    ytpc,
  };

  const serializedParams = getSerializedParams(updatesParams);

  try {
    const { data: respData } = yield axios.get(SWIMLANES_GET_POPULAR_CONTENT, serializedParams);

    const data = Array.isArray(respData) && respData.length ? respData[0] : respData;

    yield put(swimlanesGetPopularContent.success({ data }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(swimlanesGetPopularContent.fail());
  }
}
