import * as Yup from 'yup';

// CONSTANTS
import { MIN_ZIP_CODE_LENGTH, MAX_ZIP_CODE_LENGTH } from '@/constants/validation';

function getEmailSchema(t, isRequired) {
  const schema = Yup.string().email(t('validation.emailNotValid'));

  return isRequired ? schema.required(t('validation.emailRequired')) : schema;
}

function getZipcodeSchema(t, isRequired) {
  const schema = Yup.string()
    .min(MIN_ZIP_CODE_LENGTH, t('validation.minZipCodeLength', { length: MIN_ZIP_CODE_LENGTH }))
    .max(MAX_ZIP_CODE_LENGTH, t('validation.maxZipCodeLength', { length: MAX_ZIP_CODE_LENGTH }));

  return isRequired ? schema.required() : schema;
}

function getPasswordSchema(t, isRequired) {
  const schema = Yup.string()
    .min(10, t('validation.passwordLength', { length: 10 }))
    .matches(
      /^(?!.*[!@#$%^&*()_+=\-\\[\]{};:'",.<>?/]{5,}).*$/,
      t('validation.noMoreSpecCharactersThen', { number: 4 }),
    );

  return isRequired ? schema.required(t('validation.passwordRequired')) : schema;
}

function fieldRequiredSchema(t, isRequired) {
  const schema = Yup.string();

  return isRequired ? schema.required(t('validation.fieldRequired')) : schema;
}

const validationSchemas = {
  email: getEmailSchema,
  zipcode: getZipcodeSchema,
  password: getPasswordSchema,
};

export default (t, { fields }) => {
  return fields.reduce((currSchema, { name, type, required }) => {
    const getSchema = validationSchemas[name] || validationSchemas[type] || fieldRequiredSchema;

    if (getSchema) {
      currSchema[name] = getSchema(t, required);
    }

    return currSchema;
  }, {});
};
