import produce from 'immer';

// UTILITY
import store, { setContentCount } from '@/utility/store';

import {
  login,
  cleanLoginError,
  registerUser,
  logout,
  updateUniqueId,
  authDrawerOpen,
  authDrawerClose,
  resetPassSendEmail,
  resetPassUpdate,
  manageAuthDialogState,
  setAnonymousId,
  loginWithFirebaseToken,
} from '@/store/actions/auth';
import {
  userEditProfile,
  userUpdateFirstName,
  userGetContentCount,
  userUpdateContentCount,
  userUpdateSRBalance,
} from '@/store/actions/user';
import { onboardingComplete } from '@/store/actions/onboarding';
import { clearVirtualCardExpandedData, reissueSRCard } from '@/store/actions/streamingRewards';

const unique_id = store.get('unique_id') || '';
const token = store.get('token') || '';
const anonymousId = store.get('ajs_anonymous_id');
const cacheDependencies = store.get('content_count') || {};

const initialState = {
  user: null,
  unique_id,
  anonymousId: anonymousId || '',
  isAuth: !!token,
  errors: {},
  success: {},
  loading: false,
  authDrawerIsOpen: false,
  authDrawerProps: {},
  authDialogIsOpen: false,
  authDialogProps: {},
  cacheDependencies: cacheDependencies || {},
  firebaseLoginLoading: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // LOGIN
    case login.types.START:
      state.loading = true;
      return state;

    case login.types.SUCCESS:
      state.loading = false;
      state.isAuth = true;
      state.user = action.payload.user;
      state.authDrawerIsOpen = false;
      state.authDrawerProps = { screen: 'login' };
      state.authDialogIsOpen = false;
      state.authDialogProps = { screen: 'registration' };
      state.errors = {};

      setContentCount(action.payload.user?.content_count || {});

      return state;

    case login.types.FAIL:
      state.loading = false;
      state.isAuth = false;
      state.errors.login = action?.payload?.error;
      return state;

    // CLEAN LOGIN ERROR
    case cleanLoginError.types.INIT:
      state.errors.login = '';
      return state;

    // REGISTER
    // RESET PASS UPDATE
    case registerUser.types.START:
    case resetPassUpdate.types.START:
      state.loading = true;
      return state;

    case registerUser.types.SUCCESS:
    case resetPassUpdate.types.SUCCESS:
      state.loading = false;
      state.isAuth = true;
      state.user = action.payload.user;
      state.authDrawerIsOpen = false;
      state.authDrawerProps = { screen: 'login' };
      state.authDialogIsOpen = false;
      state.authDialogProps = { screen: 'registration' };

      setContentCount(action.payload.user?.content_count || {});

      return state;

    case registerUser.types.FAIL:
    case resetPassUpdate.types.FAIL:
      state.loading = false;
      state.isAuth = false;
      return state;

    // RESET PASS SEND EMAIL
    case resetPassSendEmail.types.START:
      state.loading = true;
      return state;

    case resetPassSendEmail.types.SUCCESS:
      state.loading = false;
      state.authDrawerIsOpen = false;
      state.authDrawerProps = { screen: 'login' };
      state.authDialogIsOpen = false;
      state.authDialogProps = { screen: 'registration' };
      return state;

    case resetPassSendEmail.types.FAIL:
      state.loading = false;
      return state;

    // LOGOUT
    case logout.types.INIT:
      state.isAuth = false;
      state.user = null;
      state.unique_id = '';
      state.errors = {};
      state.success = {};

      setContentCount({});

      return state;

    // UPDATE USER
    case userEditProfile.types.SUCCESS:
      state.user = {
        ...state.user,
        ...action.payload.user,
      };
      return state;

    // UPDATE UNIQUE ID
    case updateUniqueId.types.INIT:
      state.unique_id = action.payload.unique_id;
      return state;

    // OPEN DRAWER
    case authDrawerOpen.types.INIT:
      state.authDrawerIsOpen = true;
      state.authDrawerProps = action?.payload?.data || {};
      return state;

    // CLOSE DRAWER
    case authDrawerClose.types.INIT:
      state.authDrawerIsOpen = false;
      state.authDrawerProps = {};
      return state;

    // ONBOARDING COMPLETE
    case onboardingComplete.types.SUCCESS:
      state.user.completed_apps_onboard = true;
      return state;

    // UPDATE FIRST NAME
    case userUpdateFirstName.types.SUCCESS:
      state.user.first_name = action.payload.first_name;
      return state;

    // GET CONTENT COUNT
    case userGetContentCount.types.SUCCESS:
      if (state.user) {
        state.user.content_count = action.payload.data;

        setContentCount(action.payload.data || state.user.content_count);
      }

      return state;

    // UPDATE CONTENT COUNT
    case userUpdateContentCount.types.INIT:
      state.user.content_count = {
        ...state.user.content_count,
        ...action.payload.data,
      };

      setContentCount(state.user.content_count);

      return state;

    // MANAGE AUTH DIALOG STATE
    case manageAuthDialogState.types.INIT:
      state.authDialogIsOpen = action.payload.state;
      state.authDialogProps = action?.payload?.props || {};
      return state;

    // SET ANONYMOUS ID
    case setAnonymousId.types.INIT:
      state.anonymousId = action.payload.id;
      return state;

    // UPDATE CARD BALANCE
    case userUpdateSRBalance.type:
      if (state.user?.card) state.user.card.balance = action.payload.balance;
      return state;

    // CLEAR VIRTUAL CARD EXPANDED DATA
    case clearVirtualCardExpandedData.types.INIT:
      if (state.user?.card) {
        state.user.card.number = null;
        state.user.card.cvc = null;
      }
      return state;

    // LOGIN WITH FIREBASE TOKEN
    case loginWithFirebaseToken.types.START:
      state.firebaseLoginLoading = true;
      return state;
    case loginWithFirebaseToken.types.STOP:
      state.firebaseLoginLoading = false;
      return state;

    // REISSUE CARD
    case reissueSRCard.types.SUCCESS:
      if (state.user) state.user.card = action.payload.data.card;

      return state;

    default:
      return state;
  }
});

export default reducer;
